// @ts-nocheck
import classNames from 'classnames';
import memoize from 'lodash.memoize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { MeasurementService, ServicesManager, Types, ViewportGridService } from '@core';
import { LoadingIndicatorProgress, ViewportGrid, ViewportPane, useViewportGrid } from '@ui';
import EmptyViewport from './EmptyViewport';

const onElementEnabled = memoize(
	(viewportGridService: ViewportGridService, viewportId: string): (() => void) => {
		if (!viewportGridService || !viewportId) {
			return () => {};
		}
		return () => {
			viewportGridService.setViewportIsReady(viewportId, true);
		};
	},
	(viewportGridService: ViewportGridService, viewportId: string) => {
		return viewportId;
	}
);

const ViewerViewportGrid = (props) => {
	const { servicesManager, viewportComponents, dataSource } = props;
	const { state: viewportGrid, api: viewportGridService } = useViewportGrid();

	const { layout, activeViewportId, viewports, isHangingProtocolLayout } = viewportGrid;
	const { numCols, numRows } = layout;
	const elementRef = useRef(null);
	const layoutHash = useRef(null);

	// TODO -> Need some way of selecting which displaySets hit the viewports.
	const { displaySetService, measurementService, hangingProtocolService, uiNotificationService } = (servicesManager as ServicesManager)
		.services;

	const _getUpdatedViewports = useCallback(
		(viewportId, displaySetInstanceUID) => {
			let updatedViewports = [];
			try {
				updatedViewports = hangingProtocolService.getViewportsRequireUpdate(
					viewportId,
					displaySetInstanceUID,
					isHangingProtocolLayout
				);
			} catch (error) {
				console.warn(error);
				uiNotificationService.show({
					title: 'Drag and Drop',
					message:
						'The selected display sets could not be added to the viewport due to a mismatch in the Hanging Protocol rules.',
					type: 'info',
					duration: 3000
				});
			}

			return updatedViewports;
		},
		[hangingProtocolService, uiNotificationService, isHangingProtocolLayout]
	);

	// Using Hanging protocol engine to match the displaySets
	useEffect(() => {
		/**
		 * This callback runs after the viewports structure has changed in any way.
		 * On initial display, that means if it has changed by applying a HangingProtocol,
		 * while subsequently it may mean by changing the stage or by manually adjusting
		 * the layout.
		 */
		const updateDisplaySetsFromProtocol = (protocol: Types.HangingProtocol.Protocol, stage, activeStudyUID, viewportMatchDetails) => {
			const availableDisplaySets = displaySetService.getActiveDisplaySets();

			if (!availableDisplaySets.length) {
				console.log('No available display sets', availableDisplaySets);
				return;
			}

			// Match each viewport individually
			const { layoutType } = stage.viewportStructure;
			const stageProps = stage.viewportStructure.properties;
			const { columns: numCols, rows: numRows, layoutOptions = [] } = stageProps;

			/**
			 * This find or create viewport uses the hanging protocol results to
			 * specify the viewport match details, which specifies the size and
			 * setup of the various viewports.
			 */
			const findOrCreateViewport = (pos) => {
				const viewportId = Array.from(viewportMatchDetails.keys())[pos];
				const details = viewportMatchDetails.get(viewportId);
				if (!details) {
					console.log('No match details for viewport', viewportId);
					return;
				}

				const { displaySetsInfo, viewportOptions } = details;
				const displaySetUIDsToHang = [];
				const displaySetUIDsToHangOptions = [];

				displaySetsInfo.forEach(({ displaySetInstanceUID, displaySetOptions }) => {
					if (displaySetInstanceUID) {
						displaySetUIDsToHang.push(displaySetInstanceUID);
					}

					displaySetUIDsToHangOptions.push(displaySetOptions);
				});

				const computedViewportOptions = hangingProtocolService.getComputedOptions(viewportOptions, displaySetUIDsToHang);

				const computedDisplaySetOptions = hangingProtocolService.getComputedOptions(
					displaySetUIDsToHangOptions,
					displaySetUIDsToHang
				);

				return {
					displaySetInstanceUIDs: displaySetUIDsToHang,
					displaySetOptions: computedDisplaySetOptions,
					viewportOptions: computedViewportOptions
				};
			};

			viewportGridService.setLayout({
				numRows,
				numCols,
				layoutType,
				layoutOptions,
				findOrCreateViewport,
				isHangingProtocolLayout: true
			});
		};

		const { unsubscribe } = hangingProtocolService.subscribe(
			hangingProtocolService.EVENTS.PROTOCOL_CHANGED,
			({ protocol, stage, activeStudyUID, viewportMatchDetails }) => {
				updateDisplaySetsFromProtocol(protocol, stage, activeStudyUID, viewportMatchDetails);
			}
		);

		return () => {
			unsubscribe();
		};
	}, [displaySetService, hangingProtocolService, viewportGridService]);

	// Check viewport readiness in useEffect
	useEffect(() => {
		const generateLayoutHash = () => `${numCols}-${numRows}`;
		const allReady = viewportGridService.getGridViewportsReady();
		const sameLayoutHash = layoutHash.current === generateLayoutHash();
		if (allReady && !sameLayoutHash) {
			layoutHash.current = generateLayoutHash();
			viewportGridService.publishViewportsReady();
		}
	}, [viewportGridService, numCols, numRows]);

	useEffect(() => {
		const { unsubscribe } = measurementService.subscribe(
			MeasurementService.EVENTS.JUMP_TO_MEASUREMENT_LAYOUT,
			({ viewportId, measurement, isConsumed }) => {
				if (isConsumed) {
					return;
				}
				// This occurs when no viewport has elected to consume the event
				// so we need to change layouts into a layout which can consume
				// the event.
				const { displaySetInstanceUID: referencedDisplaySetInstanceUID } = measurement;

				const updatedViewports = _getUpdatedViewports(viewportId, referencedDisplaySetInstanceUID);
				// Arbitrarily assign the viewport to element 0
				const viewport = updatedViewports?.[0];

				if (!viewport) {
					console.warn('ViewportGrid::Unable to navigate to viewport containing', referencedDisplaySetInstanceUID);
					return;
				}

				viewport.viewportOptions ||= {};
				viewport.viewportOptions.orientation = 'acquisition';

				const displaySet = displaySetService.getDisplaySetByUID(referencedDisplaySetInstanceUID);
				// jump straight to the initial image index if we can
				if (displaySet.images && measurement.SOPInstanceUID) {
					for (let index = 0; index < displaySet.images.length; index++) {
						const image = displaySet.images[index];
						if (image.SOPInstanceUID === measurement.SOPInstanceUID) {
							viewport.viewportOptions.initialImageOptions = {
								index
							};
							break;
						}
					}
				}
				viewportGridService.setDisplaySetsForViewports(updatedViewports);
			}
		);

		return () => {
			unsubscribe();
		};
	}, [_getUpdatedViewports, displaySetService, measurementService, viewportGridService, viewports]);

	/**
  const onDoubleClick = viewportId => {
    // TODO -> Disabled for now.
    // onNewImage on a cornerstone viewport is firing setDisplaySetsForViewport.
    // Which it really really shouldn't. We need a larger fix for jump to
    // measurements and all cornerstone "imageIndex" state to fix this.
    if (cachedLayout) {
      viewportGridService.set({
        numCols: cachedLayout.numCols,
        numRows: cachedLayout.numRows,
        activeViewportId: cachedLayout.activeViewportId,
        viewports: cachedLayout.viewports,
        cachedLayout: null,
      });

      return;
    }

    const cachedViewports = viewports.map(viewport => {
      return {
        displaySetInstanceUID: viewport.displaySetInstanceUID,
      };
    });

    viewportGridService.set({
      numCols: 1,
      numRows: 1,
      activeViewportId: 0,
      viewports: [
        {
          displaySetInstanceUID: viewports[viewportId].displaySetInstanceUID,
          imageIndex: undefined,
        },
      ],
      cachedLayout: {
        numCols,
        numRows,
        viewports: cachedViewports,
        activeViewportId: viewportId,
      },
    });
  };
  */

	const getViewportPanes = useCallback(() => {
		const onDropHandler = (viewportId, { displaySetInstanceUID }) => {
			const updatedViewports = _getUpdatedViewports(viewportId, displaySetInstanceUID);
			viewportGridService.setDisplaySetsForViewports(updatedViewports);
		};

		const viewportPanes = [];

		const numViewportPanes = viewportGridService.getNumViewportPanes();
		for (let i = 0; i < numViewportPanes; i++) {
			const paneMetadata: any = Array.from(viewports.values())[i] || {};
			const {
				displaySetInstanceUIDs,
				viewportOptions,
				displaySetOptions, // array of options for each display set in the viewport
				x: viewportX,
				y: viewportY,
				width: viewportWidth,
				height: viewportHeight,
				viewportLabel
			} = paneMetadata;

			const viewportId = viewportOptions.viewportId;
			const isActive = activeViewportId === viewportId;

			const displaySetInstanceUIDsToUse = displaySetInstanceUIDs || [];

			// This is causing the viewport components re-render when the activeViewportId changes
			const displaySets = displaySetInstanceUIDsToUse
				.map((displaySetInstanceUID) => {
					return displaySetService.getDisplaySetByUID(displaySetInstanceUID) || {};
				})
				.filter((displaySet) => {
					return !displaySet?.unsupported;
				});

			const ViewportComponent = _getViewportComponent(displaySets, viewportComponents, uiNotificationService);

			// look inside displaySets to see if they need reRendering
			const displaySetsNeedsRerendering = displaySets.some((displaySet) => {
				return displaySet.needsRerendering;
			});

			const onInteractionHandler = (event) => {
				if (isActive) {
					return;
				}
				if (event) {
					// FIX error : Unable to preventDefault inside passive event listener invocation.
					if (event.type !== 'wheel') {
						event.preventDefault();
					}
					event.stopPropagation();
				}

				viewportGridService.setActiveViewportId(viewportId);
			};

			viewportPanes[i] = (
				<ViewportPane
					// Note: It is highly important that the key is the viewportId here,
					// since it is used to determine if the component should be re-rendered
					// by React, and also in the hanging protocol and stage changes if the
					// same viewportId is used, React, by default, will only move (not re-render)
					// those components. For instance, if we have a 2x3 layout, and we move
					// from 2x3 to 1x1 (second viewport), if the key is the viewportIndex,
					// React will RE-RENDER the resulting viewport as the key will be different.
					// however, if the key is the viewportId, React will only move the component
					// and not re-render it.
					key={viewportId}
					acceptDropsFor="displayset"
					onDrop={onDropHandler.bind(null, viewportId)}
					onInteraction={onInteractionHandler}
					customStyle={{
						position: 'absolute',
						top: viewportY * 100 + '%',
						left: viewportX * 100 + '%',
						width: viewportWidth * 100 + '%',
						height: viewportHeight * 100 + '%'
					}}
					isActive={isActive}
				>
					<div
						data-cy="viewport-pane"
						className={classNames('flex h-full w-full flex-col', {
							'pointer-events-none': !isActive
						})}
					>
						<ViewportComponent
							displaySets={displaySets}
							viewportLabel={viewports.size > 1 ? viewportLabel : ''}
							viewportId={viewportId}
							dataSource={dataSource}
							viewportOptions={viewportOptions}
							displaySetOptions={displaySetOptions}
							needsRerendering={displaySetsNeedsRerendering}
							isHangingProtocolLayout={isHangingProtocolLayout}
							onElementEnabled={onElementEnabled(viewportGridService, viewportId)}
						/>
					</div>
				</ViewportPane>
			);
		}

		return viewportPanes;
	}, [
		viewportGridService,
		_getUpdatedViewports,
		viewports,
		activeViewportId,
		viewportComponents,
		uiNotificationService,
		dataSource,
		isHangingProtocolLayout,
		displaySetService
	]);

	/**
	 * Loading indicator until numCols and numRows are gotten from the HangingProtocolService
	 */
	if (!numRows || !numCols) {
		return <LoadingIndicatorProgress className={'h-full w-full'} />;
	}

	return (
		<ViewportGrid
			numRows={numRows}
			numCols={numCols}
		>
			<ReactResizeDetector
				refreshMode="debounce"
				refreshRate={7} // ms seems to be fine for 10 viewports
				onResize={() => {
					viewportGridService.setViewportGridSizeChanged();
				}}
				targetRef={elementRef.current}
			/>
			{getViewportPanes()}
		</ViewportGrid>
	);
};

ViewerViewportGrid.propTypes = {
	viewportComponents: PropTypes.array.isRequired,
	servicesManager: PropTypes.instanceOf(ServicesManager)
};

ViewerViewportGrid.defaultProps = {
	viewportComponents: []
};

function _getViewportComponent(displaySets, viewportComponents, uiNotificationService) {
	if (!displaySets || !displaySets.length) {
		return EmptyViewport;
	}

	// Todo: Do we have a viewport that has two different SOPClassHandlerIds?
	const SOPClassHandlerId = displaySets[0].SOPClassHandlerId;

	for (let i = 0; i < viewportComponents.length; i++) {
		if (!viewportComponents[i]) {
			throw new Error('viewport components not defined');
		}
		if (!viewportComponents[i].displaySetsToDisplay) {
			throw new Error('displaySetsToDisplay is null');
		}
		if (viewportComponents[i].displaySetsToDisplay.includes(SOPClassHandlerId)) {
			const { component } = viewportComponents[i];
			return component;
		}
	}

	console.log("Can't show displaySet", SOPClassHandlerId, displaySets[0]);
	uiNotificationService.show({
		title: 'Viewport Not Supported Yet',
		message: `Cannot display SOPClassUID of ${displaySets[0].SOPClassUID} yet`,
		type: 'error'
	});

	return EmptyViewport;
}

export default ViewerViewportGrid;
