import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../Icon';
import Typography from '../Typography';
import uuidv4 from '@core/utils/uuidv4';

/**
 * REACT CheckBox component
 * it has two props, checked and onChange
 * checked is a boolean value
 * onChange is a function that will be called when the checkbox is clicked
 *
 * CheckBox is a component that allows you to use as a boolean
 */

const CheckBox: React.FC<{
	checked: boolean;
	onChange: (state) => void;
	className?: string;
	label: string;
	labelClassName?: string;
	labelVariant?: string;
	disabled?: boolean;
}> = ({ checked, onChange, label, labelClassName, labelVariant = 'body', className, disabled = false }) => {
	const [isChecked, setIsChecked] = useState<boolean>();

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleClick = useCallback(() => {
		if (!disabled) {
			setIsChecked(!isChecked);
			onChange(!isChecked);
		}
	}, [disabled, isChecked, onChange]);

	return (
		<div
			className={`flex cursor-pointer items-center space-x-1${className ? className : ''}`}
			onClick={handleClick}
		>
			<Icon
				name={isChecked ? 'checkbox-checked' : 'checkbox-unchecked'}
				className={`${isChecked ? 'fill-primary-active stroke-primary-active hover:fill-primary-light hover:stroke-primary-light' : ''} h-4 w-4`}
			/>

			<Typography
				variant={labelVariant ?? 'subtitle'}
				component="p"
				className={labelClassName ?? 'text-sm text-white'}
			>
				{label}
			</Typography>
		</div>
	);
};

CheckBox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	label: PropTypes.string,
	labelClassName: PropTypes.string,
	labelVariant: PropTypes.string
};

export default CheckBox;
