import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonEnums } from '../Button';

const InputGroupRadioButton = (props) => {
	const {
		groupClasses,
		label,
		hideLabel,
		labelClasses,
		buttonGroupsClasses,
		buttonClasses,
		resource,
		source,
		input,
		inputValue,
		onInputChange,
		choices,
		optionValue,
		optionText,
		allowEmpty,
		emptyChoiceLabel,
		disabled,
		labelNamespace
	} = props;
	const { t } = useTranslation(labelNamespace);
	const translatedLabel = label ? t(label) : t(`resources.${resource}.fields.${source}`);

	const value = input && input.value ? input.value : inputValue && inputValue[source];

	const onClick = (e) => {
		const newValue = e.currentTarget.dataset.value;
		// window.vDebug.log('new val', newValue);
		if (onInputChange) {
			onInputChange({ [source]: newValue });
		}
		if (input && input.onChange) {
			input.onChange(newValue);
		}
	};

	return (
		<div className={groupClasses}>
			{!hideLabel && <label className={classNames('col-form-label', labelClasses)}>{translatedLabel}</label>}
			<div
				className={buttonGroupsClasses}
				role="group"
				aria-label="Select an option"
			>
				{choices.map((choice) => {
					const choiceValue = get(choice, optionValue);
					const choiceText = get(choice, optionText);
					return (
						<Button
							key={choiceValue}
							size={ButtonEnums.size.small}
							disabled={disabled}
							className={classNames('btn-viewer-secondary', value === choiceValue ? 'active' : '', buttonClasses)}
							onClick={onClick}
							data-value={choiceValue}
							type={choices.type}
						>
							{t(choiceText)}
						</Button>
					);
				})}
				{allowEmpty && (
					<Button
						size={ButtonEnums.size.small}
						disabled={disabled}
						className={classNames('btn-viewer-secondary', !value ? 'active' : '', buttonClasses)}
						onClick={onClick}
						type={choices.type}
					>
						{t(emptyChoiceLabel)}
					</Button>
				)}
			</div>
		</div>
	);
};

InputGroupRadioButton.propTypes = {
	label: PropTypes.string,
	hideLabel: PropTypes.bool,
	choices: PropTypes.array,
	emptyChoiceLabel: PropTypes.string,
	groupClasses: PropTypes.string,
	labelClasses: PropTypes.string,
	buttonGroupsClasses: PropTypes.string,
	buttonClasses: PropTypes.string,
	inputValue: PropTypes.any,
	onInputChange: PropTypes.func,
	optionValue: PropTypes.any,
	optionText: PropTypes.any,
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
	resource: PropTypes.string,
	source: PropTypes.string,
	input: PropTypes.object,
	labelNamespace: PropTypes.string
};
InputGroupRadioButton.defaultProps = {
	optionValue: 'id',
	optionText: 'name',
	allowEmpty: true,
	buttonGroupsClasses: 'group-btn-pick-date'
};

export default InputGroupRadioButton;
