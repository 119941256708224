import React from 'react';
import PropTypes from 'prop-types';

import InputLabelWrapper from '../InputLabelWrapper';
import CustomDateRangePicker from '../DateRangePicker';
import { CustomDateRangePickerValue } from '../DateRangePicker/CustomDateRangePicker';

const InputDateRange = ({ id, label, isSortable, sortDirection, onLabelClick, value, onChange }) => {
	const { startDate, endDate } = value as CustomDateRangePickerValue;
	const onClickHandler = (event) => {
		event.preventDefault();
		onLabelClick(event);
	};

	return (
		<InputLabelWrapper
			label={label}
			isSortable={isSortable}
			sortDirection={sortDirection}
			onLabelClick={onClickHandler}
		>
			<div className="absolute">
				<CustomDateRangePicker
					value={{ startDate, endDate }}
					onChange={onChange}
				/>
			</div>
		</InputLabelWrapper>
	);
};

const noop = () => {};

InputDateRange.defaultProps = {
	value: {},
	onLabelClick: noop
};

InputDateRange.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string.isRequired,
	isSortable: PropTypes.bool.isRequired,
	sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none']).isRequired,
	onLabelClick: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};

export default InputDateRange;
