import classes, { CommandsManager, HotkeysManager } from './classes';
import { ExtensionManager, MODULE_TYPES } from './extensions';
import { ServiceProvidersManager, ServicesManager } from './services';

import DICOMWeb from './DICOMWeb';
import defaults from './defaults';
import * as Enums from './enums';
import errorHandler from './errorHandler';
import { useToolbar } from './hooks/useToolbar';
import log from './log';
import object from './object';
import string from './string';
import * as Types from './types';
import user from './user';
import utils from './utils';

import {
	CustomizationService,
	//
	DicomMetadataStore,
	DisplaySetService,
	HangingProtocolService,
	MeasurementService,
	PanelService,
	PubSubService,
	pubSubServiceInterface,
	SessionAuthenticationService,
	StateSyncService,
	StudyPrefetcherService,
	ToolbarService,
	TrackingDataService,
	UIDialogService,
	UIModalService,
	UINotificationService,
	UIViewportDialogService,
	ViewportGridService,
	WorkflowStepsService
} from './services';

import { DisplaySetMessage, DisplaySetMessageList } from './services/DisplaySetService';

import IWebApiDataSource from './DataSources/IWebApiDataSource';

export { SessionAuthenticationProvider, SessionInfomationContext, useUserAuthentication } from './contextProviders';

const hotkeys = {
	...utils.hotkeys,
	defaults: { hotkeyBindings: defaults.hotkeyBindings }
};

const Core = {
	MODULE_TYPES,
	//
	CommandsManager,
	ExtensionManager,
	HotkeysManager,
	ServicesManager,
	ServiceProvidersManager,
	//
	defaults,
	utils,
	hotkeys,
	classes,
	string,
	user,
	errorHandler,
	object,
	log,
	DICOMWeb,
	viewer: {},
	//
	CustomizationService,
	StateSyncService,
	UIDialogService,
	UIModalService,
	UINotificationService,
	UIViewportDialogService,
	DisplaySetService,
	MeasurementService,
	ToolbarService,
	ViewportGridService,
	HangingProtocolService,
	SessionAuthenticationService,
	IWebApiDataSource,
	DicomMetadataStore,
	pubSubServiceInterface,
	PubSubService,
	PanelService,
	useToolbar,
	WorkflowStepsService,
	TrackingDataService,
	StudyPrefetcherService
};

export {
	classes,
	//
	//
	CommandsManager,
	CustomizationService,
	//
	defaults,
	DicomMetadataStore,
	DICOMWeb,
	DisplaySetMessage,
	DisplaySetMessageList,
	DisplaySetService,
	Enums,
	errorHandler,
	ExtensionManager,
	HangingProtocolService,
	hotkeys,
	HotkeysManager,
	IWebApiDataSource,
	log,
	MeasurementService,
	MODULE_TYPES,
	object,
	PanelService,
	PubSubService,
	pubSubServiceInterface,
	ServiceProvidersManager,
	ServicesManager,
	SessionAuthenticationService,
	StateSyncService,
	string,
	StudyPrefetcherService,
	ToolbarService,
	TrackingDataService,
	Types,
	UIDialogService,
	UIModalService,
	UINotificationService,
	UIViewportDialogService,
	user,
	useToolbar,
	utils,
	ViewportGridService,
	WorkflowStepsService
};

export type { CommandsModule, UtilitiesModule, ViewportModule } from './extensions';
export type * from './services';

export { Core };

export default Core;
