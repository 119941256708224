import React, { useEffect } from 'react';

import { useUserAuthentication } from '@core';
import { useSession } from '@hooks';
import { useAppConfig } from '@state';
import { Auth } from './Auth/AuthPage';

export const PrivateRoute = ({ children, sessionAuthenticationService, commandsManager }) => {
	const { getSession } = useSession(commandsManager);
	const { session } = useUserAuthentication();
	const { requiresShareKey, sessionKey } = session;
	const appConfig = useAppConfig();

	// Set thông tin session vào Authentication Provider
	useEffect(() => {
		const setSession = async () => {
			const sessionData = await getSession();
			if (sessionData) {
				sessionAuthenticationService.setSession(sessionData);
			}
		};
		!appConfig.standalone && setSession();
	}, [appConfig.standalone, getSession, sessionAuthenticationService]);

	if (requiresShareKey && !sessionKey) {
		return <Auth />;
	}
	return children;
};

export default PrivateRoute;
