import React from 'react';
// Icons

import { ReactComponent as arrowDown } from './../../assets/icons/arrow-down.svg';
import { ReactComponent as arrowLeftSmall } from './../../assets/icons/arrow-left-small.svg';
import { ReactComponent as arrowLeft } from './../../assets/icons/arrow-left.svg';
import { ReactComponent as arrowRightSmall } from './../../assets/icons/arrow-right-small.svg';
import { ReactComponent as arrowUp } from './../../assets/icons/arrow-up.svg';
import { ReactComponent as calendar } from './../../assets/icons/calendar.svg';
import { ReactComponent as cancel } from './../../assets/icons/cancel.svg';
import { ReactComponent as checkboxChecked } from './../../assets/icons/checkbox-checked.svg';
import { ReactComponent as checkboxUnchecked } from './../../assets/icons/checkbox-unchecked.svg';
import { ReactComponent as chevronDown } from './../../assets/icons/chevron-down.svg';
import { ReactComponent as chevronLeft } from './../../assets/icons/chevron-left.svg';
import { ReactComponent as chevronMenu } from './../../assets/icons/chevron-menu.svg';
import { ReactComponent as chevronNext } from './../../assets/icons/chevron-next.svg';
import { ReactComponent as chevronPrev } from './../../assets/icons/chevron-prev.svg';
import { ReactComponent as chevronRight } from './../../assets/icons/chevron-right.svg';
import { ReactComponent as chevronUp } from './../../assets/icons/chevron-up.svg';
import { ReactComponent as circledCheckmark } from './../../assets/icons/circled-checkmark.svg';
import { ReactComponent as clipboard } from './../../assets/icons/clipboard.svg';
import { ReactComponent as close } from './../../assets/icons/closeIcon.svg';
import { ReactComponent as dottedCircle } from './../../assets/icons/dotted-circle.svg';
import { ReactComponent as exclamation } from './../../assets/icons/exclamation.svg';
import { ReactComponent as externalLink } from './../../assets/icons/external-link.svg';
import { ReactComponent as eyeHidden } from './../../assets/icons/eye-hidden.svg';
import { ReactComponent as eyeVisible } from './../../assets/icons/eye-visible.svg';
import { ReactComponent as groupLayers } from './../../assets/icons/group-layers.svg';
import { ReactComponent as iconAlertSmall } from './../../assets/icons/icon-alert-small.svg';
import { ReactComponent as iconClearField } from './../../assets/icons/icon-clear-field.svg';
import { ReactComponent as iconClose } from './../../assets/icons/icon-close.svg';
import { ReactComponent as iconNextInactive } from './../../assets/icons/icon-next-inactive.svg';
import { ReactComponent as iconNext } from './../../assets/icons/icon-next.svg';
import { ReactComponent as iconPause } from './../../assets/icons/icon-pause.svg';
import { ReactComponent as iconPlay } from './../../assets/icons/icon-play.svg';
import { ReactComponent as iconPrevInactive } from './../../assets/icons/icon-prev-inactive.svg';
import { ReactComponent as iconPrev } from './../../assets/icons/icon-prev.svg';
import { ReactComponent as iconSearchWhite } from './../../assets/icons/icon-search-white.svg';
import { ReactComponent as iconSearch } from './../../assets/icons/icon-search.svg';
import { ReactComponent as iconStatusAlert } from './../../assets/icons/icon-status-alert.svg';
import { ReactComponent as iconTransferring } from './../../assets/icons/icon-transferring.svg';
import { ReactComponent as iconUpload } from './../../assets/icons/icon-upload.svg';
import { ReactComponent as iconAlertOutline } from './../../assets/icons/icons-alert-outline.svg';
import { ReactComponent as infoAction } from './../../assets/icons/info-action.svg';
import { ReactComponent as infoLink } from './../../assets/icons/info-link.svg';
import { ReactComponent as info } from './../../assets/icons/info.svg';
import { ReactComponent as launchArrow } from './../../assets/icons/launch-arrow.svg';
import { ReactComponent as launchInfo } from './../../assets/icons/launch-info.svg';
import { ReactComponent as link } from './../../assets/icons/link.svg';
import { ReactComponent as listBullets } from './../../assets/icons/list-bullets.svg';
import { ReactComponent as loadingOHIFMark } from './../../assets/icons/loading-ohif-mark.svg';
import { ReactComponent as lock } from './../../assets/icons/lock.svg';
import { ReactComponent as logoOhifSmall } from './../../assets/icons/logo-ohif-small.svg';
import { ReactComponent as magnifier } from './../../assets/icons/magnifier.svg';
import { ReactComponent as navigationPanelRightHide } from './../../assets/icons/navigation-panel-right-hide.svg';
import { ReactComponent as navigationPanelRightReveal } from './../../assets/icons/navigation-panel-right-reveal.svg';
import { ReactComponent as notificationsError } from './../../assets/icons/notifications-error.svg';
import { ReactComponent as notificationsInfo } from './../../assets/icons/notifications-info.svg';
import { ReactComponent as notificationsSuccess } from './../../assets/icons/notifications-success.svg';
import { ReactComponent as notificationsWarning } from './../../assets/icons/notifications-warning.svg';
import { ReactComponent as notificationwarningDiamond } from './../../assets/icons/notificationwarning-diamond.svg';
import { ReactComponent as pencil } from './../../assets/icons/pencil.svg';
import { ReactComponent as powerOff } from './../../assets/icons/power-off.svg';
import { ReactComponent as profile } from './../../assets/icons/profile.svg';
import { ReactComponent as pushLeft } from './../../assets/icons/push-left.svg';
import { ReactComponent as pushRight } from './../../assets/icons/push-right.svg';
import { ReactComponent as settings } from './../../assets/icons/settings.svg';
import { ReactComponent as sidePanelCloseLeft } from './../../assets/icons/side-panel-close-left.svg';
import { ReactComponent as sidePanelCloseRight } from './../../assets/icons/side-panel-close-right.svg';
import { ReactComponent as sidePanelCollapse } from './../../assets/icons/side-panel-collapse.svg';
import { ReactComponent as sortingActiveDown } from './../../assets/icons/sorting-active-down.svg';
import { ReactComponent as sortingActiveUp } from './../../assets/icons/sorting-active-up.svg';
import { ReactComponent as sorting } from './../../assets/icons/sorting.svg';
import { ReactComponent as statusAlertWarning } from './../../assets/icons/status-alert-warning.svg';
import { ReactComponent as statusAlert } from './../../assets/icons/status-alert.svg';
import { ReactComponent as statusLocked } from './../../assets/icons/status-locked.svg';
import { ReactComponent as statusTracked } from './../../assets/icons/status-tracked.svg';
import { ReactComponent as statusUntracked } from './../../assets/icons/status-untracked.svg';
import { ReactComponent as synchronization } from './../../assets/icons/synchronization.svg';
import { ReactComponent as tabLinear } from './../../assets/icons/tab-linear.svg';
import { ReactComponent as tabPatientInfo } from './../../assets/icons/tab-patient-info.svg';
import { ReactComponent as tabROIThreshold } from './../../assets/icons/tab-roi-threshold.svg';
import { ReactComponent as tabSegmentation } from './../../assets/icons/tab-segmentation.svg';
import { ReactComponent as tabStudies } from './../../assets/icons/tab-studies.svg';
import { ReactComponent as tracked } from './../../assets/icons/tracked.svg';
import { ReactComponent as uiArrowDown } from './../../assets/icons/ui-arrow-down.svg';
import { ReactComponent as uiArrowLeft } from './../../assets/icons/ui-arrow-left.svg';
import { ReactComponent as uiArrowRight } from './../../assets/icons/ui-arrow-right.svg';
import { ReactComponent as uiArrowUp } from './../../assets/icons/ui-arrow-up.svg';
import { ReactComponent as unlink } from './../../assets/icons/unlink.svg';

/** Tools */
import { ReactComponent as checkboxActive } from './../../assets/icons/checkbox-active.svg';
import { ReactComponent as checkboxDefault } from './../../assets/icons/checkbox-default.svg';
import { ReactComponent as editPatient } from './../../assets/icons/edit-patient.svg';
import { ReactComponent as iconAdd } from './../../assets/icons/icon-add.svg';
import { ReactComponent as iconDelete } from './../../assets/icons/icon-delete.svg';
import { ReactComponent as chevronDownNew } from './../../assets/icons/icon-disclosure-close.svg';
import { ReactComponent as chevronLeftNew } from './../../assets/icons/icon-disclosure-open.svg';
import { ReactComponent as settingsBars } from './../../assets/icons/icon-display-settings.svg';
import { ReactComponent as iconMoreMenu } from './../../assets/icons/icon-more-menu.svg';
import { ReactComponent as iconMPR } from './../../assets/icons/icon-mpr-alt.svg';
import { ReactComponent as iconRename } from './../../assets/icons/icon-rename.svg';
import { ReactComponent as iconToolBrush } from './../../assets/icons/icon-tool-brush.svg';
import { ReactComponent as iconToolEraser } from './../../assets/icons/icon-tool-eraser.svg';
import { ReactComponent as iconToolScissor } from './../../assets/icons/icon-tool-scissor.svg';
import { ReactComponent as iconToolShape } from './../../assets/icons/icon-tool-shape.svg';
import { ReactComponent as iconToolThreshold } from './../../assets/icons/icon-tool-threshold.svg';
import { ReactComponent as panelGroupMore } from './../../assets/icons/panel-group-more.svg';
import { ReactComponent as panelGroupOpenClose } from './../../assets/icons/panel-group-open-close.svg';
import { ReactComponent as referenceLines } from './../../assets/icons/reference-lines.svg';
import { ReactComponent as rowAdd } from './../../assets/icons/row-add.svg';
import { ReactComponent as rowEdit } from './../../assets/icons/row-edit.svg';
import { ReactComponent as rowHidden } from './../../assets/icons/row-hidden.svg';
import { ReactComponent as rowLock } from './../../assets/icons/row-lock.svg';
import { ReactComponent as rowShown } from './../../assets/icons/row-shown.svg';
import { ReactComponent as rowUnlock } from './../../assets/icons/row-unlock.svg';
import { ReactComponent as toggleDicomOverlay } from './../../assets/icons/toggle-dicom-overlay.svg';
import { ReactComponent as toolAngle } from './../../assets/icons/tool-angle.svg';
import { ReactComponent as toolAnnotate } from './../../assets/icons/tool-annotate.svg';
import { ReactComponent as toolBidirectional } from './../../assets/icons/tool-bidirectional.svg';
import { ReactComponent as toolCalibration } from './../../assets/icons/tool-calibration.svg';
import { ReactComponent as toolCapture } from './../../assets/icons/tool-capture.svg';
import { ReactComponent as toolCine } from './../../assets/icons/tool-cine.svg';
import { ReactComponent as toolCircle } from './../../assets/icons/tool-circle.svg';
import { ReactComponent as toolCreateThreshold } from './../../assets/icons/tool-create-threshold.svg';
import { ReactComponent as toolCrosshair } from './../../assets/icons/tool-crosshair.svg';
import { ReactComponent as toolElipse } from './../../assets/icons/tool-elipse.svg';
import { ReactComponent as toolFlipHorizontal } from './../../assets/icons/tool-flip-horizontal.svg';
import { ReactComponent as toolFlipVertical } from './../../assets/icons/tool-flip-vertical.svg';

import { ReactComponent as toolFreehandPolygon } from './../../assets/icons/tool-freehand-polygon.svg';
import { ReactComponent as toolFreehand } from './../../assets/icons/tool-freehand.svg';
import { ReactComponent as toolFusionColor } from './../../assets/icons/tool-fusion-color.svg';
import { ReactComponent as toolFusion } from './../../assets/icons/tool-fusion.svg';
import { ReactComponent as toolInvert } from './../../assets/icons/tool-invert.svg';
import { ReactComponent as toolLayout } from './../../assets/icons/tool-layout.svg';
import { ReactComponent as toolLength } from './../../assets/icons/tool-length.svg';
import { ReactComponent as toolMagnify } from './../../assets/icons/tool-magnify.svg';
import { ReactComponent as toolMIP } from './../../assets/icons/tool-mip.svg';
import { ReactComponent as toolMore } from './../../assets/icons/tool-more-menu.svg';
import { ReactComponent as toolMove } from './../../assets/icons/tool-move.svg';
import { ReactComponent as toolPolygon } from './../../assets/icons/tool-polygon.svg';
import { ReactComponent as toolProbe } from './../../assets/icons/tool-probe.svg';
import { ReactComponent as toolRectangle } from './../../assets/icons/tool-rectangle.svg';
import { ReactComponent as toolReferenceLines } from './../../assets/icons/tool-reference-lines.svg';
import { ReactComponent as toolReset } from './../../assets/icons/tool-reset.svg';
import { ReactComponent as toolRotateLeft } from './../../assets/icons/tool-rotate-left.svg';
import { ReactComponent as toolRotateRight } from './../../assets/icons/tool-rotate-right.svg';
import { ReactComponent as toolRotate } from './../../assets/icons/tool-rotate.svg';
import { ReactComponent as toolStackScroll } from './../../assets/icons/tool-stack-scroll.svg';
import { ReactComponent as toolSwitchView } from './../../assets/icons/tool-switch-view.svg';
import { ReactComponent as toolWindow } from './../../assets/icons/tool-window-level.svg';
import { ReactComponent as toolZoom } from './../../assets/icons/tool-zoom.svg';

/** Old OHIF */
import { ReactComponent as oldPlay } from './../../assets/icons/old-play.svg';
import { ReactComponent as oldStop } from './../../assets/icons/old-stop.svg';
import { ReactComponent as oldTrash } from './../../assets/icons/old-trash.svg';

/** V2 */
import { ReactComponent as dicomTag } from './../../assets/icons/dicomtag.svg';
import { ReactComponent as folder } from './../../assets/icons/folder.svg';
import { ReactComponent as hanging } from './../../assets/icons/hanging.svg';
import { ReactComponent as save } from './../../assets/icons/save.svg';
import { ReactComponent as share } from './../../assets/icons/share.svg';
import { ReactComponent as adjustWindowCenter } from './../../assets/icons/tool-adjust-window-center.svg';
import { ReactComponent as toolCobbAngle } from './../../assets/icons/tool-cobb-angle.svg';
import { ReactComponent as toolCross } from './../../assets/icons/tool-cross.svg';
import { ReactComponent as toolHeartChest } from './../../assets/icons/tool-heart-chest.svg';
import { ReactComponent as point } from './../../assets/icons/tool-point.svg';
import { ReactComponent as text } from './../../assets/icons/tool-text.svg';

import { ReactComponent as exportImage } from './../../assets/icons/export.svg';
import { ReactComponent as hideInfo } from './../../assets/icons/hide-info.svg';
import { ReactComponent as toolAxial } from './../../assets/icons/icon-axial.svg';
import { ReactComponent as toolCoronal } from './../../assets/icons/icon-coronal.svg';
import { ReactComponent as iconCursor } from './../../assets/icons/icon-cursor.svg';
import { ReactComponent as iconExpand } from './../../assets/icons/icon-expand.svg';
import { ReactComponent as image } from './../../assets/icons/icon-image.svg';
import { ReactComponent as inverse } from './../../assets/icons/icon-inverse.svg';
import { ReactComponent as iconMore } from './../../assets/icons/icon-more.svg';
import { ReactComponent as iconQrScanning } from './../../assets/icons/icon-qr-scanning.svg';
import { ReactComponent as toolSagittal } from './../../assets/icons/icon-sagittal.svg';
import { ReactComponent as iconSkipback } from './../../assets/icons/skip-back-icon.svg';
import { ReactComponent as iconNextback } from './../../assets/icons/skip-next-icon.svg';
import { ReactComponent as threeColumn } from './../../assets/icons/three-column.svg';
import { ReactComponent as threeViewport } from './../../assets/icons/three-viewport.svg';
import { ReactComponent as dicomTagBrower } from './../../assets/icons/tool-dicom-tag-browser.svg';
import { ReactComponent as twoColumn } from './../../assets/icons/two-column.svg';
import { ReactComponent as twoRow } from './../../assets/icons/two-row.svg';

import { ReactComponent as iconBin } from './../../assets/icons/bin.svg';
import { ReactComponent as keyImage } from './../../assets/icons/key-image.svg';
import { ReactComponent as layoutSetting } from './../../assets/icons/layout-setting.svg';
import { ReactComponent as axial } from './../../assets/icons/orientation/axial.svg';
import { ReactComponent as coronal } from './../../assets/icons/orientation/coronal.svg';
import { ReactComponent as sagittal } from './../../assets/icons/orientation/sagittal.svg';
import { ReactComponent as siemensColor } from './../../assets/icons/pseudoColor/Siemens.svg';
import { ReactComponent as geColor } from './../../assets/icons/pseudoColor/ge.svg';
import { ReactComponent as grayscaleColor } from './../../assets/icons/pseudoColor/grayscale.svg';
import { ReactComponent as hotIronColor } from './../../assets/icons/pseudoColor/hot_iron.svg';
import { ReactComponent as hsvColor } from './../../assets/icons/pseudoColor/hsv.svg';
import { ReactComponent as perfusionColor } from './../../assets/icons/pseudoColor/perfusion.svg';
import { ReactComponent as rainbowColor } from './../../assets/icons/pseudoColor/rainbow.svg';
import { ReactComponent as redHotColor } from './../../assets/icons/pseudoColor/red_hot.svg';
import { ReactComponent as spetColor } from './../../assets/icons/pseudoColor/s_pet.svg';
import { ReactComponent as suvColor } from './../../assets/icons/pseudoColor/suv.svg';

import { ReactComponent as icon3dPreset } from './../../assets/icons/icon-3d-preset.svg';
import { ReactComponent as icon3dRevert } from './../../assets/icons/icon-3d-revert.svg';
import { ReactComponent as iconBed } from './../../assets/icons/icon-bed.svg';
import { ReactComponent as iconCrop } from './../../assets/icons/icon-crop.svg';
import { ReactComponent as iconFreecut } from './../../assets/icons/icon-freecut.svg';
import { ReactComponent as icon3D1 } from './../../assets/icons/icon-layout-3d1.svg';
import { ReactComponent as icon3D2 } from './../../assets/icons/icon-layout-3d2.svg';
import { ReactComponent as icon3D3 } from './../../assets/icons/icon-layout-3d3.svg';
import { ReactComponent as threeDRotate } from './../../assets/icons/three-d-rotate.svg';
import { ReactComponent as history } from './../../assets/icons/history.svg';

const ICONS = {
	'arrow-down': arrowDown,
	'arrow-up': arrowUp,
	'arrow-left': arrowLeft,
	'arrow-left-small': arrowLeftSmall,
	'arrow-right-small': arrowRightSmall,
	calendar: calendar,
	cancel: cancel,
	clipboard: clipboard,
	close: close,
	'dotted-circle': dottedCircle,
	'circled-checkmark': circledCheckmark,
	'chevron-down': chevronDown,
	'chevron-up': chevronUp,
	'chevron-left': chevronLeft,
	'chevron-menu': chevronMenu,
	'chevron-next': chevronNext,
	'chevron-prev': chevronPrev,
	'chevron-right': chevronRight,
	'eye-visible': eyeVisible,
	'eye-hidden': eyeHidden,
	'external-link': externalLink,
	'group-layers': groupLayers,
	info: info,
	'icon-alert-outline': iconAlertOutline,
	'icon-alert-small': iconAlertSmall,
	'icon-clear-field': iconClearField,
	'icon-close': iconClose,
	'icon-play': iconPlay,
	'icon-pause': iconPause,
	'icon-search': iconSearch,
	'icon-search-white': iconSearchWhite,
	'icon-status-alert': iconStatusAlert,
	'icon-transferring': iconTransferring,
	'info-action': infoAction,
	'info-link': infoLink,
	'launch-arrow': launchArrow,
	'launch-info': launchInfo,
	link: link,
	'list-bullets': listBullets,
	lock: lock,
	'logo-ohif-small': logoOhifSmall,
	magnifier: magnifier,
	exclamation: exclamation,
	'notificationwarning-diamond': notificationwarningDiamond,
	pencil: pencil,
	'power-off': powerOff,
	profile: profile,
	'push-left': pushLeft,
	'push-right': pushRight,
	settings: settings,
	'side-panel-close-left': sidePanelCloseLeft,
	'side-panel-close-right': sidePanelCloseRight,
	'side-panel-collapse': sidePanelCollapse,
	'sorting-active-down': sortingActiveDown,
	'sorting-active-up': sortingActiveUp,
	'status-alert': statusAlert,
	'status-alert-warning': statusAlertWarning,
	'status-locked': statusLocked,
	'status-tracked': statusTracked,
	'status-untracked': statusUntracked,
	sorting: sorting,
	synchronization: synchronization,
	tracked: tracked,
	unlink: unlink,
	'panel-group-more': panelGroupMore,
	'panel-group-open-close': panelGroupOpenClose,
	'row-add': rowAdd,
	'row-edit': rowEdit,
	'row-hidden': rowHidden,
	'row-shown': rowShown,
	'row-lock': rowLock,
	'row-unlock': rowUnlock,
	'checkbox-checked': checkboxChecked,
	'checkbox-unchecked': checkboxUnchecked,
	'loading-ohif-mark': loadingOHIFMark,
	'notifications-info': notificationsInfo,
	'notifications-error': notificationsError,
	'notifications-success': notificationsSuccess,
	'notifications-warning': notificationsWarning,

	/** Tools */
	'toggle-dicom-overlay': toggleDicomOverlay,
	'tool-zoom': toolZoom,
	'tool-rotate': toolRotate,
	'tool-capture': toolCapture,
	'tool-layout': toolLayout,
	'tool-more-menu': toolMore,
	'tool-move': toolMove,
	'tool-window-level': toolWindow,
	'tool-annotate': toolAnnotate,
	'tool-bidirectional': toolBidirectional,
	'tool-elipse': toolElipse,
	'tool-circle': toolCircle,
	'tool-length': toolLength,
	'tool-stack-scroll': toolStackScroll,
	'tool-magnify': toolMagnify,
	'tool-flip-horizontal': toolFlipHorizontal,
	'tool-flip-vertical': toolFlipVertical,
	'tool-invert': toolInvert,
	'tool-rotate-right': toolRotateRight,
	'tool-rotate-left': toolRotateLeft,
	'tool-cine': toolCine,
	'tool-crosshair': toolCrosshair,
	'tool-probe': toolProbe,
	'tool-angle': toolAngle,
	'tool-reset': toolReset,
	'tool-rectangle': toolRectangle,
	'tool-fusion-color': toolFusionColor,
	'tool-create-threshold': toolCreateThreshold,
	'tool-calibration': toolCalibration,
	'tool-point': toolCircle,
	'tool-freehand-line': toolFreehand,
	'tool-freehand-polygon': toolFreehandPolygon,
	'tool-polygon': toolPolygon,
	'tool-reference-lines': toolReferenceLines,
	'tool-switch-view': toolSwitchView,
	'tool-mip': toolMIP,
	'tool-fusion': toolFusion,
	'edit-patient': editPatient,
	'icon-mpr': iconMPR,
	'icon-next-inactive': iconNextInactive,
	'icon-next': iconNext,
	'icon-prev-inactive': iconPrevInactive,
	'icon-prev': iconPrev,
	'icon-upload': iconUpload,
	'navigation-panel-right-hide': navigationPanelRightHide,
	'navigation-panel-right-reveal': navigationPanelRightReveal,
	'tab-linear': tabLinear,
	'tab-patient-info': tabPatientInfo,
	'tab-roi-threshold': tabROIThreshold,
	'tab-segmentation': tabSegmentation,
	'tab-studies': tabStudies,
	'ui-arrow-down': uiArrowDown,
	'ui-arrow-up': uiArrowUp,
	'ui-arrow-left': uiArrowLeft,
	'ui-arrow-right': uiArrowRight,
	'checkbox-default': checkboxDefault,
	'checkbox-active': checkboxActive,
	'tool-referenceLines': referenceLines,
	'chevron-left-new': chevronLeftNew,
	'chevron-down-new': chevronDownNew,
	'settings-bars': settingsBars,
	'icon-rename': iconRename,
	'icon-add': iconAdd,
	'icon-delete': iconDelete,
	'icon-more-menu': iconMoreMenu,
	'icon-tool-brush': iconToolBrush,
	'icon-tool-eraser': iconToolEraser,
	'icon-tool-scissor': iconToolScissor,
	'icon-tool-shape': iconToolShape,
	'icon-tool-threshold': iconToolThreshold,

	/** Old OHIF */
	'old-trash': oldTrash,
	'old-play': oldPlay,
	'old-stop': oldStop,

	/** V2 */
	save: save,
	share: share,
	folder: folder,
	'dicom-tag': dicomTag,
	'dicom-tag-browers': dicomTagBrower,
	hanging: hanging,
	'adjust-window-center': adjustWindowCenter,
	point: point,
	text: text,
	cross: toolCross,
	'cobb-angle': toolCobbAngle,
	'tool-heart-chest': toolHeartChest,
	'tool-coronal': toolCoronal,
	'tool-axial': toolAxial,
	'tool-sagittal': toolSagittal,
	more: iconMore,
	expand: iconExpand,
	'qr-scanning': iconQrScanning,
	'icon-cursor': iconCursor,
	'two-column': twoColumn,
	'three-column': threeColumn,
	'three-viewport': threeViewport,
	'two-row': twoRow,
	image: image,
	inverse: inverse,
	skipback: iconSkipback,
	skipnext: iconNextback,
	'hide-info': hideInfo,
	'export-image': exportImage,

	/**
	 * pseudo color
	 */
	geColor: geColor,
	siemensColor: siemensColor,
	grayscaleColor: grayscaleColor,
	hotIronColor: hotIronColor,
	hsvColor: hsvColor,
	perfusionColor: perfusionColor,
	rainbowColor: rainbowColor,
	redHotColor: redHotColor,
	spetColor: spetColor,
	suvColor: suvColor,

	/**
	 * icon orientation overlay
	 */
	axial: axial,
	sagittal: sagittal,
	coronal: coronal,
	'layout-setting': layoutSetting,
	bin: iconBin,
	'key-image': keyImage,
	'icon-bed': iconBed,
	'icon-crop': iconCrop,
	'icon-freecut': iconFreecut,
	'icon-3d-revert': icon3dRevert,
	'icon-3d-preset': icon3dPreset,
	'icon-3d1': icon3D1,
	'icon-3d2': icon3D2,
	'icon-3d3': icon3D3,
	'three-d-rotate': threeDRotate,
	'icon-history': history
};

function addIcon(iconName, iconSVG) {
	if (ICONS[iconName]) {
		console.warn(`Icon ${iconName} already exists.`);
	}

	ICONS[iconName] = iconSVG;
}

/**
 * Return the matching SVG Icon as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getIcon(key, props) {
	if (!key || !ICONS[key]) {
		return React.createElement('div', null, 'Missing Icon');
	}

	return React.createElement(ICONS[key], props);
}

export { addIcon, getIcon, ICONS };
