import { useCallback, useEffect, useState } from 'react';

import { getSelectedButtonSection } from '@core/utils/getSelectedButtonSection';
import { useGetDisplaysetModality } from '@hooks';

const DEFAULT_BUTTON_SECTION = 'default';
//
const DEFAULT_ZOOM_TOOL_MODALITY = ['DX', 'CR', 'MG', 'DR'];

export function useToolbar({ servicesManager, buttonSection = DEFAULT_BUTTON_SECTION }: withAppTypes) {
	const { toolbarService, viewportGridService } = servicesManager.services;
	const [toolbarButtons, setToolbarButtons] = useState(toolbarService.getButtonSection(buttonSection));
	const modality = useGetDisplaysetModality(servicesManager);
	// Callback function for handling toolbar interactions
	const onInteraction = useCallback(
		(args) => {
			const viewportId = viewportGridService.getActiveViewportId();
			const refreshProps = {
				viewportId
			};
			toolbarService.recordInteraction(args, {
				refreshProps
			});
		},
		[toolbarService, viewportGridService]
	);

	useEffect(() => {
		/**
		 * Chuột trái: kích hoạt chức năng theo modality
		 * DX, CR, MG: kích hoạt chức năng Zoom
		 * Các modality khác: kích hoạt chức năng Scroll
		 */
		if (DEFAULT_ZOOM_TOOL_MODALITY.includes(modality)) {
			onInteraction({
				itemId: 'Zoom',
				interactionType: 'action',
				commands: {
					commandName: 'setToolActiveToolbar'
				}
			});
		} else {
			onInteraction({
				itemId: 'StackScroll',
				interactionType: 'action',
				commands: {
					commandName: 'setToolActiveToolbar'
				}
			});
		}
	}, [modality, onInteraction]);

	// Effect to handle toolbar modification events
	useEffect(() => {
		const { EVENTS } = toolbarService;

		const handleToolbarModified = () => {
			const currentButtonSection = getSelectedButtonSection({ toolbarService, buttonSection });
			setToolbarButtons(toolbarService.getButtonSection(currentButtonSection));
		};

		const subs = [EVENTS.TOOL_BAR_MODIFIED, EVENTS.TOOL_BAR_STATE_MODIFIED].map((event) => {
			return toolbarService.subscribe(event, handleToolbarModified);
		});

		return () => {
			subs.forEach((sub) => sub.unsubscribe());
		};
	}, [toolbarService, buttonSection]);

	// Effect to handle active viewportId change event
	useEffect(() => {
		const events = [viewportGridService.EVENTS.ACTIVE_VIEWPORT_ID_CHANGED, viewportGridService.EVENTS.VIEWPORTS_READY];

		const subscriptions = events.map((event) => {
			return viewportGridService.subscribe(event, ({ viewportId }) => {
				viewportId = viewportId || viewportGridService.getActiveViewportId();
				toolbarService.refreshToolbarState({ viewportId });
			});
		});

		return () => subscriptions.forEach((sub) => sub.unsubscribe());
	}, [viewportGridService, toolbarService]);

	return { toolbarButtons, onInteraction };
}
