// @ts-nocheck

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CommandsManager, ServicesManager } from '@core';
import NavBar from '../NavBar';
import Svg from '../Svg';
import { AddOnButtons } from './AddOnButtons';
import { PluginSwitch } from './PluginSwitch';

function Header({
	children = undefined,
	// menuOptions,
	isReturnEnabled,
	onClickReturnButton = undefined,
	isSticky,
	WhiteLabeling,
	commandsManager = undefined,
	servicesManager = undefined,
	extensionManager,
	...props
}): ReactNode {
	const { t } = useTranslation('Header');

	// TODO: this should be passed in as a prop instead and the react-router-dom
	// dependency should be dropped
	const onClickReturn = () => {
		if (isReturnEnabled && onClickReturnButton) {
			onClickReturnButton();
		}
	};

	return (
		<NavBar
			className="min-h-11 justify-between border-black"
			isSticky={isSticky}
		>
			<div className="flex w-full">
				<div
					className="flex items-center justify-between"
					style={{ width: '192px' }}
				>
					{/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}

					<div
						onClick={onClickReturn}
						className={classNames('mx-2', isReturnEnabled && 'cursor-pointer')}
					>
						{/* {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo" />} */}
					</div>
					{/*
								TODO: đổi tên thành Hanging Protocol Switcher, render item theo config hanging protocol
							*/}
					{servicesManager && (
						<PluginSwitch
							servicesManager={servicesManager}
							commandsManager={commandsManager}
							extensionManager={extensionManager}
						/>
					)}
				</div>
				<div className="flex w-full flex-1 gap-12">
					<div className="relative flex flex-1 items-center">{children}</div>
					<AddOnButtons />
				</div>
			</div>
		</NavBar>
	);
}

Header.propTypes = {
	menuOptions: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			icon: PropTypes.string,
			onClick: PropTypes.func.isRequired
		})
	),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	isReturnEnabled: PropTypes.bool,
	isSticky: PropTypes.bool,
	onClickReturnButton: PropTypes.func,
	WhiteLabeling: PropTypes.object,
	commandsManager: PropTypes.instanceOf(CommandsManager),
	servicesManager: PropTypes.instanceOf(ServicesManager)
};

Header.defaultProps = {
	isReturnEnabled: true,
	isSticky: false
};

export default Header;
