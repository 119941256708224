import PropTypes from 'prop-types';
import React from 'react';

import { StringNumber } from '../../types';
import StudyItem from '../StudyItem';
import ThumbnailList from '../ThumbnailList';

const getTrackedSeries = (displaySets) => {
	let trackedSeries = 0;
	displaySets.forEach((displaySet) => {
		if (displaySet.isTracked) {
			trackedSeries++;
		}
	});

	return trackedSeries;
};

const StudyBrowser = ({
	tabs,
	activeTabName,
	expandedStudyInstanceUIDs,
	onClickTab,
	onClickStudy,
	onClickThumbnail,
	onDoubleClickThumbnail,
	onClickUntrack,
	activeDisplaySetInstanceUIDs,
	servicesManager,
	onSubPanel,
	onMobile
}) => {
	// const { t } = useTranslation('StudyBrowser');
	// const { customizationService } = servicesManager?.services || {};
	const getTabContent = () => {
		const tabData = tabs.find((tab) => tab.name === activeTabName);
		return tabData.studies.map(
			({ studyInstanceUid, date, description, numInstances, modalities, displaySets, patientName, patientBirthDate }) => {
				const isExpanded = expandedStudyInstanceUIDs.includes(studyInstanceUid);
				const numSeries = displaySets.length;
				return (
					<React.Fragment key={studyInstanceUid}>
						{!onMobile && (
							<StudyItem
								date={date}
								description={description}
								numInstances={numInstances}
								modalities={modalities}
								trackedSeries={getTrackedSeries(displaySets)}
								isActive={isExpanded}
								onClick={() => {
									onClickStudy(studyInstanceUid);
								}}
								patientName={patientName}
								patientBirthDate={patientBirthDate}
								numSeries={numSeries}
								onSubPanel={onSubPanel}
								data-cy="thumbnail-list"
							/>
						)}
						{isExpanded && displaySets && (
							<ThumbnailList
								thumbnails={displaySets}
								activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
								onThumbnailClick={onClickThumbnail}
								onThumbnailDoubleClick={onDoubleClickThumbnail}
								onClickUntrack={onClickUntrack}
								servicesManager={servicesManager}
								onSubPanel={onSubPanel}
								onMobile={onMobile}
							/>
						)}
					</React.Fragment>
				);
			}
		);
	};

	return (
		<React.Fragment>
			{/* <div
				className="w-100 flex h-16 flex-row items-center justify-center border-b border-secondary-light bg-primary-dark p-4"
				data-cy={'studyBrowser-panel'}
			>
				<LegacyButtonGroup
					variant="outlined"
					color="secondary"
					splitBorder={false}
				>
					{tabs.map((tab) => {
						const { name, label, studies } = tab;
						const isActive = activeTabName === name;
						const isDisabled = !studies.length;
						// Apply the contrasting color for brighter button color visibility
						const classStudyBrowser = customizationService?.getModeCustomization('class:StudyBrowser') || {
							true: 'default',
							false: 'default'
						};
						const color = classStudyBrowser[`${isActive}`];
						return (
							<LegacyButton
								key={name}
								className={'min-w-18 p-2 text-base text-white'}
								size="initial"
								color={color}
								bgColor={isActive ? 'bg-primary-main' : 'bg-black'}
								onClick={() => {
									onClickTab(name);
								}}
								disabled={isDisabled}
							>
								{t(label)}
							</LegacyButton>
						);
					})}
				</LegacyButtonGroup>
			</div> */}
			<div className="invisible-scrollbar flex flex-col gap-1 overflow-hidden">{getTabContent()}</div>
		</React.Fragment>
	);
};

StudyBrowser.propTypes = {
	onClickTab: PropTypes.func.isRequired,
	onClickStudy: PropTypes.func,
	onClickThumbnail: PropTypes.func,
	onDoubleClickThumbnail: PropTypes.func,
	onClickUntrack: PropTypes.func,
	activeTabName: PropTypes.string.isRequired,
	expandedStudyInstanceUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			studies: PropTypes.arrayOf(
				PropTypes.shape({
					studyInstanceUid: PropTypes.string.isRequired,
					date: PropTypes.string,
					numInstances: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
					modalities: PropTypes.string,
					description: PropTypes.string,
					displaySets: PropTypes.arrayOf(
						PropTypes.shape({
							displaySetInstanceUID: PropTypes.string.isRequired,
							imageSrc: PropTypes.string,
							imageAltText: PropTypes.string,
							seriesDate: PropTypes.string,
							seriesNumber: StringNumber,
							numInstances: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
							description: PropTypes.string,
							componentType: PropTypes.oneOf(['thumbnail', 'thumbnailTracked', 'thumbnailNoImage']).isRequired,
							isTracked: PropTypes.bool,
							viewportIdentificator: PropTypes.arrayOf(PropTypes.string),
							/**
							 * Data the thumbnail should expose to a receiving drop target. Use a matching
							 * `dragData.type` to identify which targets can receive this draggable item.
							 * If this is not set, drag-n-drop will be disabled for this thumbnail.
							 *
							 * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
							 */
							dragData: PropTypes.shape({
								/** Must match the "type" a dropTarget expects */
								type: PropTypes.string.isRequired
							})
						})
					)
				})
			).isRequired
		})
	),
	servicesManager: PropTypes.object,
	onSubPanel: PropTypes.bool,
	onMobile: PropTypes.bool
};

const noop = () => undefined;

StudyBrowser.defaultProps = {
	onClickTab: noop,
	onClickStudy: noop,
	onClickThumbnail: noop,
	onDoubleClickThumbnail: noop,
	onClickUntrack: noop
};

export default StudyBrowser;
