import { ServicesManager } from '@core';
import { useViewportGrid } from '@ui';
import { ViewportState } from '@ui/contextProviders';

export const useGetDisplaysetModality = (servicesManager: ServicesManager) => {
	const { displaySetService } = servicesManager.services;
	const { state: viewportGrid } = useViewportGrid();
	const { viewports, activeViewportId } = viewportGrid as ViewportState;
	const activeViewport = viewports.get(activeViewportId);
	const displaySet =
		displaySetService &&
		activeViewport &&
		activeViewport.displaySetInstanceUIDs &&
		activeViewport.displaySetInstanceUIDs &&
		activeViewport.displaySetInstanceUIDs.length &&
		activeViewport.displaySetInstanceUIDs[0]
			? displaySetService.getDisplaySetByUID(activeViewport.displaySetInstanceUIDs[0])
			: undefined;

	// Modality of current displaySet
	const modality = displaySet?.Modality;

	return modality;
};
