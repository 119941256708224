import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

const borderSize = 4;
const expandedWidth = 240;
const collapsedWidth = 25;
const closeIconWidth = 24;
const gridHorizontalPadding = 10;
const tabSpacerWidth = 2;
const gridAvailableWidth = expandedWidth - closeIconWidth - gridHorizontalPadding;

const baseStyle = {
	maxWidth: `${expandedWidth}px`,
	width: `${expandedWidth}px`,
	// To align the top of the type panel with the top of the viewport grid, use position relative and offset the
	// top by the same top offset as the viewport grid. Also adjust the height so that there is no overflow.
	position: 'relative',
	top: '0%',
	height: '100%'
};

const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;
const styleMap = {
	open: {
		toolSidebar: {
			marginLeft: '0px'
		},
		seriesSidebar: {
			marginRight: '0px'
		}
	},
	closed: {
		toolSidebar: {
			marginLeft: `-${collapsedHideWidth}px`
		},
		seriesSidebar: {
			marginRight: `-${collapsedHideWidth}px`
		}
	}
};

const baseClasses = 'transition-all duration-300 ease-in-out bg-primary-black border-common-dark justify-start box-content flex flex-col';

const classesMap = {
	open: {
		toolSidebar: ``,
		seriesSidebar: ``
	},
	closed: {
		toolSidebar: `mr-1 items-end`,
		seriesSidebar: `ml-1 items-start`
	}
};

const getTabWidth = (numTabs: number) => {
	if (numTabs < 3) {
		return 68;
	} else {
		return 40;
	}
};

const getGridWidth = (numTabs: number) => {
	const spacersWidth = (numTabs - 1) * tabSpacerWidth;
	const tabsWidth = getTabWidth(numTabs) * numTabs;

	if (gridAvailableWidth > tabsWidth + spacersWidth) {
		return tabsWidth + spacersWidth;
	}

	return gridAvailableWidth;
};

const getNumGridColumns = (numTabs: number) => {
	if (numTabs === 1) {
		return 1;
	}

	// Start by calculating the number of tabs assuming each tab was accompanied by a spacer.
	const tabWidth = getTabWidth(numTabs);
	const gridWidth = getGridWidth(numTabs);
	const numTabsWithOneSpacerEach = Math.floor(gridWidth / (tabWidth + tabSpacerWidth));

	// But there is always one less spacer than tabs, so now check if an extra tab with one less spacer fits.
	if ((numTabsWithOneSpacerEach + 1) * tabWidth + numTabsWithOneSpacerEach * tabSpacerWidth <= gridWidth) {
		return numTabsWithOneSpacerEach + 1;
	}

	return numTabsWithOneSpacerEach;
};

const getGridStyle = (type: string, numTabs = 0): CSSProperties => {
	const gridWidth = getGridWidth(numTabs);
	const relativePosition = Math.max(0, Math.floor(expandedWidth - gridWidth) / 2 - closeIconWidth);
	return {
		position: 'relative',
		...(type === 'toolSidebar'
			? {
					seriesSidebar: `${relativePosition}px`
				}
			: {
					toolSidebar: `${relativePosition}px`
				}),
		width: `${gridWidth}px`
	};
};

const getTabClassNames = (numColumns: number, numTabs: number, tabIndex: number, isActiveTab: boolean) =>
	classnames('h-[24px] mb-[2px] cursor-pointer text-common-light bg-primary-black', {
		'hover:text-primary-active': !isActiveTab,
		'rounded-l': tabIndex % numColumns === 0,
		'rounded-r': (tabIndex + 1) % numColumns === 0 || tabIndex === numTabs - 1
	});

const getTabStyle = (numTabs: number) => {
	return {
		width: `${getTabWidth(numTabs)}px`
	};
};

const getTabIconClassNames = (numTabs: number, isActiveTab: boolean) => {
	return classnames('h-full w-full flex items-center justify-center', {
		'bg-customblue-40': isActiveTab,
		rounded: isActiveTab
	});
};

const SidePanel = ({ type, className, activeTabIndex: activeTabIndexProp, tabs, style: customStyleCfg = { collapse: {} }, onOpen }) => {
	// const { t } = useTranslation('SidePanel'); // dùng cho đa ngôn ngữ

	const [panelOpen, setPanelOpen] = useState(activeTabIndexProp !== null);
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const openStatus = panelOpen ? 'open' : 'closed';
	// assign customStyle cuối củng để ghi đè style nếu cần thiết
	const { collapse: customCollapse = {}, ...customStyle } = customStyleCfg;
	const style = Object.assign({}, styleMap[openStatus][type], baseStyle, customStyle, customCollapse[openStatus]);

	const ActiveComponent = tabs[activeTabIndex]?.content;

	const updatePanelOpen = useCallback(
		(panelOpen: boolean) => {
			setPanelOpen(panelOpen);
			if (panelOpen) {
				onOpen?.();
			}
		},
		[onOpen]
	);

	const updateActiveTabIndex = useCallback(
		(activeTabIndex: number) => {
			if (activeTabIndex === null) {
				updatePanelOpen(false);
				return;
			}

			setActiveTabIndex(activeTabIndex);
			updatePanelOpen(true);
		},
		[updatePanelOpen]
	);

	useEffect(() => {
		updateActiveTabIndex(activeTabIndexProp);
	}, [activeTabIndexProp, updateActiveTabIndex]);

	const getCloseStateComponent = () => {
		const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
		return (
			<>
				<div
					className={classnames(
						'flex h-[24px] w-[24px] cursor-pointer items-center',
						'hover:bg-primary-light',
						'rounded-sm',
						type === 'toolSidebar' ? 'justify-end' : 'justify-start'
					)}
					onClick={() => {
						updatePanelOpen(!panelOpen);
					}}
					data-cy={`type-panel-header-${type}`}
				>
					<Icon
						name={'side-panel-collapse'}
						className={classnames('text-common-light', 'rotate-90', 'transform')}
					/>
				</div>
				<div className={classnames('flex flex-col space-y-3')}>
					{_childComponents.map((childComponent, index) => {
						let label = '';
						if (typeof childComponent.label === 'function') {
							label = childComponent.label();
						} else {
							label = childComponent.label;
						}
						return (
							<Tooltip
								key={index}
								content={label}
								className={classnames('flex items-center', type === 'toolSidebar' ? 'justify-end ' : 'justify-start ')}
							>
								<div
									id={`${childComponent.name}-btn`}
									data-cy={`${childComponent.name}-btn`}
									className="rotate-180 select-none py-1 text-[14px] text-common-light hover:cursor-pointer"
									onClick={() => {
										updateActiveTabIndex(index);
									}}
									style={{
										writingMode: 'vertical-lr'
									}}
								>
									{label}
								</div>
							</Tooltip>
						);
					})}
				</div>
			</>
		);
	};

	const getCloseIcon = () => {
		return (
			<div
				className={classnames(
					'flex h-[24px] cursor-pointer items-center justify-center',
					'hover:bg-primary-light',
					'rounded-sm',
					'order-last'
				)}
				style={{
					width: `${closeIconWidth}px`
				}}
				onClick={() => {
					updatePanelOpen(!panelOpen);
				}}
				data-cy={`type-panel-header-${type}`}
			>
				<Icon
					name="side-panel-collapse"
					className="text-common-light"
				/>
			</div>
		);
	};

	const getTabGridComponent = () => {
		const numCols = getNumGridColumns(tabs.length);

		return (
			<div className={classnames('flex grow ', type === 'seriesSidebar' ? 'justify-start' : 'justify-end')}>
				<div
					className={classnames('flex flex-wrap bg-primary-dark text-common-light')}
					style={getGridStyle(type, tabs.length)}
				>
					{tabs.map((tab, tabIndex) => {
						return (
							<React.Fragment key={tabIndex}>
								{tabIndex % numCols !== 0 && (
									<div className={classnames('flex h-[24px] w-[2px] items-center bg-primary-dark', tabSpacerWidth)}>
										<div className="h-[20px] w-full bg-primary-dark"></div>
									</div>
								)}
								<Tooltip
									position={'bottom'}
									key={tabIndex}
									content={`${tab.label}`}
								>
									<div
										className={getTabClassNames(numCols, tabs.length, tabIndex, tabIndex === activeTabIndex)}
										style={getTabStyle(tabs.length)}
										onClick={() => updateActiveTabIndex(tabIndex)}
										data-cy={`${tab.name}-btn`}
									>
										<div className={getTabIconClassNames(tabs.length, tabIndex === activeTabIndex)}>
											<Icon name={tab.iconName}></Icon>
										</div>
									</div>
								</Tooltip>
							</React.Fragment>
						);
					})}
				</div>
			</div>
		);
	};

	const getOneTabComponent = () => {
		let label = '';
		if (typeof tabs[0].label === 'function') {
			label = tabs[0].label();
		} else {
			label = tabs[0].label;
		}

		return (
			<div
				className={classnames('flex grow cursor-pointer select-none self-center px-1 text-[14px] text-sm text-common-light')}
				data-cy={`${tabs[0].name}-btn`}
				onClick={() => updatePanelOpen(!panelOpen)}
			>
				<span>{label}</span>
			</div>
		);
	};

	const getOpenStateComponent = () => {
		return (
			<div className="flex rounded-t">
				{getCloseIcon()}
				{tabs.length === 1 ? getOneTabComponent() : getTabGridComponent()}
			</div>
		);
	};
	return (
		<div
			className={classnames(className, baseClasses, classesMap[openStatus][type])}
			style={style}
		>
			{panelOpen ? (
				<>
					{getOpenStateComponent()}
					<ActiveComponent />
				</>
			) : (
				<React.Fragment>{getCloseStateComponent()}</React.Fragment>
			)}
		</div>
	);
};

SidePanel.defaultProps = {
	defaultComponentOpen: null,
	activeTabIndex: null // the default is to close the type panel
};

SidePanel.propTypes = {
	type: PropTypes.oneOf(['toolSidebar', 'seriesSidebar']).isRequired,
	className: PropTypes.string,
	activeTabIndex: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			iconName: PropTypes.string.isRequired,
			iconLabel: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
			content: PropTypes.func // TODO: Should be node, but it keeps complaining?
		})
	),
	style: PropTypes.object,
	onOpen: PropTypes.func,
	onActiveTabIndexChange: PropTypes.func,
	expandedWidth: PropTypes.number
};

export default SidePanel;
