import { CommandsManager, ServicesManager, Enums, log } from '@core';
import { sortingCriteria } from '@core/utils/sortStudy';
import getStudies from './studiesList';

/**
 * HÀM GỌI API, XỬ LÝ LẤY STUDY, SERIES METADATA
 * dùng trong:  defaultRouteInit (lần đâu mở viewer), tính năng patient history (mở thêm các studies mới trên viewer)
 */
export const getNewStudies = async ({
	servicesManager,
	studyInstanceUIDs,
	commandsManager,
	dataSource,
	hangingProtocolId
}: {
	servicesManager: ServicesManager;
	studyInstanceUIDs: string[];
	commandsManager: CommandsManager;
	dataSource;
	hangingProtocolId: string;
}) => {
	const { customizationService, hangingProtocolService, displaySetService } = servicesManager.services;
	function applyHangingProtocol() {
		const displaySets = displaySetService.getActiveDisplaySets();

		if (!displaySets || !displaySets.length) {
			return;
		}

		// Gets the studies list to use
		const studies = getStudies(studyInstanceUIDs, displaySets);
		// study being displayed, and is thus the "active" study.
		const activeStudy = studies[0];

		// run the hanging protocol matching on the displaySets with the predefined
		// hanging protocol in the mode configuration
		hangingProtocolService.run({ studies, activeStudy, displaySets }, hangingProtocolId);
	}
	log.time(Enums.TimingEnum.STUDY_TO_DISPLAY_SETS);
	log.time(Enums.TimingEnum.STUDY_TO_FIRST_IMAGE);
	const allRetrieves = studyInstanceUIDs.map((StudyInstanceUID) =>
		dataSource.retrieve.series.metadata({
			StudyInstanceUID,
			returnPromises: true,
			sortCriteria: customizationService.get('sortingCriteria') || sortingCriteria.seriesSortCriteria.seriesInfoSortingCriteria
		})
	);

	await Promise.allSettled(allRetrieves).then(async (promises) => {
		const rejectedPromises = promises.filter((promise) => promise.status === 'rejected');
		if (rejectedPromises.length !== 0) {
			console.log('rejectedPromises', rejectedPromises);
			commandsManager.run({ commandName: 'showCannotViewImagesPopup' });
			return;
		}

		log.timeEnd(Enums.TimingEnum.STUDY_TO_DISPLAY_SETS);
		log.time(Enums.TimingEnum.DISPLAY_SETS_TO_FIRST_IMAGE);
		log.time(Enums.TimingEnum.DISPLAY_SETS_TO_ALL_IMAGES);
		const allPromises = [];
		const remainingPromises = [];

		function startRemainingPromises(remainingPromises) {
			remainingPromises.forEach((p) => p.forEach((p) => p.start()));
		}

		promises.forEach((promise) => {
			// @ts-ignore
			const retrieveSeriesMetadataPromise = promise.value;
			if (Array.isArray(retrieveSeriesMetadataPromise)) {
				const { requiredSeries, remaining } = hangingProtocolService.filterSeriesRequiredForRun(
					hangingProtocolId,
					retrieveSeriesMetadataPromise
				);
				const requiredSeriesPromises = requiredSeries.map((promise) => promise.start());
				allPromises.push(Promise.allSettled(requiredSeriesPromises));
				remainingPromises.push(remaining);
			}
		});

		await Promise.allSettled(allPromises).then(applyHangingProtocol);
		startRemainingPromises(remainingPromises);
		applyHangingProtocol();
	});
};
