export * from './Modality';
export * from './PatientHistory';
export * from './preset';
export * from './resource';
export * from './rpcCommands';
export * from './Session';

export const WADO_URI = 'wado-uri';
export const QIDO_RS = 'qido-rs';
export const WADO_RS = 'wado-rs';
export enum LocalForageKey {
	Preset = 'preset'
}
export const toolGroupIds = ['default', 'mpr', 'SRToolGroup', 'volume3d', 'ctToolGroup', 'ptToolGroup', 'fusionToolGroup'];
export enum MprPlanes {
	Axial = 'Axial',
	Coronal = 'Coronal',
	Sagittal = 'Sagittal'
}

export enum ModeID {
	'default' = 'default',
	'mpr' = 'mpr',
	'remoteRender3D' = 'remoteRender3D',
	'pt/ct' = 'pt/ct'
}
