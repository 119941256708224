import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useEffect } from 'react';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';

const Dialog = ({
	title,
	text,
	onClose,
	noCloseButton,
	actions,
	onShow,
	onSubmit,
	header: HeaderComponent,
	body: BodyComponent,
	footer: FooterComponent,
	value: defaultValue
}) => {
	const [value, setValue] = useState(defaultValue);

	const theme = 'bg-primary-dark';
	const flex = 'flex flex-col';
	const border = 'border-0 rounded';
	const outline = 'outline-none focus:outline-none';
	const position = 'relative';
	const width = 'w-full';
	const padding = 'px-[20px] pb-[20px] pt-[13px]';

	useEffect(() => {
		if (onShow) {
			onShow();
		}
	}, [onShow]);

	return (
		<div className={classNames(theme, flex, border, outline, position, width, padding)}>
			<HeaderComponent
				title={title}
				noCloseButton={noCloseButton}
				onClose={onClose}
				value={value}
				setValue={setValue}
			/>
			<BodyComponent
				text={text}
				value={value}
				setValue={setValue}
			/>
			<FooterComponent
				actions={actions}
				onSubmit={onSubmit}
				value={value}
				setValue={setValue}
			/>
		</div>
	);
};

Dialog.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	onShow: PropTypes.func,
	onClose: PropTypes.func,
	noCloseButton: PropTypes.bool,
	header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	body: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	footer: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	onSubmit: PropTypes.func.isRequired,
	value: PropTypes.object,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			value: PropTypes.any,
			type: PropTypes.oneOf(['primary', 'secondary', 'cancel']).isRequired
		})
	).isRequired
};

Dialog.defaultProps = {
	header: Header,
	footer: Footer,
	body: Body,
	value: {}
};

export default Dialog;
