import React, { useCallback } from 'react';
import { LocalForageKey, ModalityType, Preset } from 'types';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import localforage from 'config/localForageConfig';
import { ServicesManager } from '@core';
import { CustomWWWLInput } from './CustomNumberInput';

const inputClasses =
	'h-6 rounded border w-full border-common-light bg-primary-main px-1 py-0.5 text-xs leading-tight placeholder-common-light shadow transition duration-300 hover:border-primary-light focus:border-primary-light focus:outline-none disabled:border-inputfield-disabled';

/**
 * Form nhập thông tin preset
 */
export const UserDefinedPresetForm = ({
	refSubmitButton,
	servicesManager,
	formControls
}: {
	refSubmitButton: React.MutableRefObject<HTMLInputElement>;
	servicesManager: ServicesManager;
	formControls: UseFormReturn<Preset, any, undefined>;
}) => {
	const { register, handleSubmit, reset } = formControls;
	const { dataTrackingService } = servicesManager.services;

	/**
	 * set thông tin preset trong form vào local
	 */
	const onSubmit: SubmitHandler<Preset> = useCallback(
		async (data) => {
			if (!data.name || !data.wl || !data.ww) {
				return;
			}
			const currentPresets = ((await localforage.getItem(LocalForageKey['Preset'])) as Preset[]) ?? [];
			const numOfPreset = currentPresets.length;
			let findPreset = currentPresets.find((item) => item.name === data.name);
			if (!findPreset) {
				await localforage.setItem(LocalForageKey['Preset'], [...currentPresets, { ...data, index: numOfPreset + 1 }]);
			} else {
				findPreset = { ...findPreset, wl: data.wl, ww: data.ww, modality: data.modality };
				const newPresets = currentPresets.filter((item) => item.name !== findPreset.name);
				await localforage.setItem(LocalForageKey['Preset'], [...newPresets, findPreset]);
			}
			reset();
			dataTrackingService.presetDataChanged(LocalForageKey['Preset']);
		},
		[dataTrackingService, reset]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="grid grid-cols-2 gap-2"
		>
			<div className="grid grid-cols-[40px_1fr]">
				<div className="w-10 text-sm !text-common-light">Name</div>
				<input
					type="text"
					className={inputClasses}
					{...register('name')}
					autoComplete="off"
				></input>
			</div>
			<div className="grid grid-cols-[40px_1fr]">
				<div className="w-10 text-sm !text-common-light">Type</div>

				<select
					{...register('modality')}
					className={inputClasses}
				>
					<option
						value={'ALL'}
						key={'ALL'}
					>
						ALL
					</option>
					{ModalityType.map((item) => (
						<option
							value={item}
							key={item}
						>
							{item}
						</option>
					))}
				</select>
			</div>
			<div className="grid grid-cols-[40px_1fr]">
				<div className="w-10 text-sm !text-common-light">WW</div>
				<input
					type="number"
					className={inputClasses}
					{...register('ww')}
				></input>
			</div>
			<div className="grid grid-cols-[40px_1fr]">
				<div className="w-10 text-sm !text-common-light">WL</div>
				<input
					type="number"
					className={inputClasses}
					{...register('wl')}
				></input>
			</div>

			<input
				type="submit"
				ref={refSubmitButton}
				hidden={true}
			/>
		</form>
	);
};
