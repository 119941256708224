// @ts-nocheck

import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Icon from '../Icon';
import IconButton from '../IconButton';

import './LegacyCinePlayerCustomInputRange.css';

const LegacyCinePlayer = ({
	isPlaying,
	minCineRate,
	maxCineRate,
	stepCineRate,
	cineRate: defaultCineRate,
	onCineRateChange,
	onPlayPauseChange,
	onClose
}) => {
	const [cineRate, setCineRate] = useState(defaultCineRate);
	const debouncedSetCineRate = debounce(onCineRateChange, 300);

	const onCineRateChangeHandler = ({ target }) => {
		const cineRate = parseFloat(target.value);
		debouncedSetCineRate(cineRate);
		setCineRate(cineRate);
	};

	const onPlayPauseChangeHandler = () => onPlayPauseChange(!isPlaying);

	const action = {
		false: {
			icon: 'old-play'
		},
		true: {
			icon: 'old-stop'
		}
	};

	return (
		<div className="LegacyCinePlayer flex h-10 flex-row items-center justify-center rounded-full border border-primary-light">
			<IconButton
				variant="text"
				color="inherit"
				size="initial"
				className="ml-4 mr-3 text-primary-active"
				onClick={onPlayPauseChangeHandler}
			>
				<Icon
					width="15px"
					height="15px"
					name={action[isPlaying].icon}
				/>
			</IconButton>
			<div className="mr-3 flex h-full flex-col justify-center pl-1 pr-1 pt-2">
				<input
					type="range"
					name="cineRate"
					min={minCineRate}
					max={maxCineRate}
					step={stepCineRate}
					value={cineRate}
					onChange={onCineRateChangeHandler}
				/>
				<p className="-mt-2 text-sm text-primary-light">{`${cineRate.toFixed(1)} fps`}</p>
			</div>
			<IconButton
				color="inherit"
				size="initial"
				className="mr-3 rounded-full border border-primary-active text-primary-active"
				onClick={onClose}
			>
				<Icon name="close" />
			</IconButton>
		</div>
	);
};

const noop = () => {};

LegacyCinePlayer.defaultProps = {
	isPlaying: false,
	minCineRate: 1,
	maxCineRate: 90,
	stepCineRate: 1,
	cineRate: 24,
	onPlayPauseChange: noop,
	onCineRateChange: noop,
	onClose: noop
};

LegacyCinePlayer.propTypes = {
	/** Minimum value for range slider */
	minCineRate: PropTypes.number.isRequired,
	/** Maximum value for range slider */
	maxCineRate: PropTypes.number.isRequired,
	/** Increment range slider can "step" in either direction */
	stepCineRate: PropTypes.number.isRequired,
	cineRate: PropTypes.number.isRequired,
	/** 'true' if playing, 'false' if paused */
	isPlaying: PropTypes.bool.isRequired,
	onPlayPauseChange: PropTypes.func,
	onCineRateChange: PropTypes.func,
	onClose: PropTypes.func
};

export default LegacyCinePlayer;
