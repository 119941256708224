import React from 'react';
import { DateRangePicker, DateRangePickerProps } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import './DateRangePicker.css';
import { enGB } from 'date-fns/locale';
import { format, parse } from 'date-fns';

export type CustomDateRangePickerValue = {
	startDate: DateRangePickerProps['startDate'];
	endDate: DateRangePickerProps['endDate'];
};

const CustomDateRangePicker = ({
	value,
	onChange,
	dateFormat = 'dd/MM/yyyy'
}: {
	value: CustomDateRangePickerValue;
	onChange?: (props: CustomDateRangePickerValue) => void;
	dateFormat?: string;
}) => {
	const { startDate, endDate } = value;
	return (
		<DateRangePicker
			startDate={startDate}
			endDate={endDate}
			onStartDateChange={(date) => {
				if (date) {
					onChange({ startDate: date, endDate });
				}
			}}
			onEndDateChange={(date) => {
				if (date) {
					onChange({ startDate, endDate: date });
				}
			}}
			minimumLength={1}
			locale={enGB}
			format={dateFormat}
		>
			{({ startDateInputProps, endDateInputProps, focus }) => {
				return (
					<div className="flex flex-row gap-2">
						<input
							{...startDateInputProps}
							value={startDateInputProps.value ? startDateInputProps.value : format(startDate, dateFormat)}
							placeholder="Start date"
						/>
						<input
							{...endDateInputProps}
							value={endDateInputProps.value ? endDateInputProps.value : format(endDate, dateFormat)}
							placeholder="End date"
						/>
					</div>
				);
			}}
		</DateRangePicker>
	);
};

export default CustomDateRangePicker;
