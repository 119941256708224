// @ts-nocheck

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Icon from '../Icon';
import classNames from 'classnames';

const ContextMenu = ({ items, ...props }) => {
	if (!items) {
		return null;
	}

	return (
		<div
			data-cy="context-menu"
			className="relative z-50 block w-44 gap-1 rounded border-common-dark bg-secondary-dark px-1 py-2"
			onContextMenu={(e) => e.preventDefault()}
		>
			{items.map((item, index) => (
				<div
					key={index}
					className="relative"
				>
					{item?.subContent ? (
						<MenuItemWithSubContent
							item={item}
							{...props}
						/>
					) : (
						<MenuItem
							item={item}
							{...props}
						/>
					)}
				</div>
			))}
		</div>
	);
};

const MenuItemWithSubContent = ({ item, ...props }) => {
	const [isShowSubContent, setIsShowSubContent] = useState(false);
	const onMouseEnterItem = useCallback(() => {
		setIsShowSubContent(true);
	}, []);

	const onMouseLeaveItem = useCallback(() => {
		setIsShowSubContent(false);
	}, []);
	return (
		<div
			className="relative"
			onMouseLeave={onMouseLeaveItem}
			onMouseEnter={onMouseEnterItem}
		>
			<MenuItem
				item={item}
				{...props}
			/>
			<div className={classNames('absolute left-full top-0', !isShowSubContent && 'hidden')}>
				{item.subContent({
					commandsManager: props.commandsManager,
					servicesManager: props.servicesManager,
					onClose: props.onClose
				})}
			</div>
		</div>
	);
};

const MenuItem = ({ item, ...props }) => {
	return item?.content ? (
		item?.content()
	) : (
		<div
			data-cy="context-menu-item"
			onClick={() => item.action(item, props)}
			style={{
				justifyContent: 'space-between'
			}}
			className="flex cursor-pointer items-center px-2 py-1 text-xs text-common-bright transition duration-300 hover:bg-primary-light"
		>
			<span>{item?.label}</span>
			{item.shortcutKey && <span>({item.shortcutKey})</span>}
			{item.iconRight && (
				<Icon
					name={item.iconRight}
					className="inline"
				/>
			)}
		</div>
	);
};
ContextMenu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			action: PropTypes.func.isRequired
		})
	)
};

export default ContextMenu;
