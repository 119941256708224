import React, { FC, useState } from 'react';
import QRCode from 'react-qr-code';

import { useShareLink } from '@hooks';
import { CreateShareSessionDTO, GetShareLinkDTO } from 'types/Share';
import { useModal } from '../../contextProviders';
import Modal from '../Modal';
import ToolbarButton from '../ToolbarButton';
import ShareLinkModal from './ShareLinkModal';

const ShareLinkButton = () => {
	const { show, hide } = useModal();
	const { getShareLink } = useShareLink();

	const defaultModalShareStudyQRcode = {
		title: null,
		children: null
	};

	// Lưu title và children component cho popup'Share study QR code'
	const [stateShareLink, setStateShareLink] = useState(defaultModalShareStudyQRcode);

	const onSubmitHandler = async (form: CreateShareSessionDTO) => {
		const formData = await getShareLink(form);
		const customLink = formData.link.replace('/viewer/index.html', '/viewer');
		if (formData) {
			setStateShareLink({
				title: 'Share study QR code',
				children: (
					<QRShareLink
						link={customLink}
						shareKey={formData.shareKey}
					/>
				)
			});
		}
		hide();
	};

	return (
		<>
			<ToolbarButton
				icon={'share'}
				label={'QR Share'}
				onInteraction={() =>
					show({
						content: ShareLinkModal,
						title: 'Share link',
						customClassNameContent: 'pb-0',
						contentProps: {
							onSubmit: onSubmitHandler,
							paddingContent: 'p-2'
						}
					})
				}
				id=""
				dropdownContent=""
				type="action"
			/>
			{stateShareLink.children && (
				<Modal
					closeButton={true}
					shouldCloseOnEsc={true}
					isOpen={true}
					title={stateShareLink.title}
					onClose={() => {
						setStateShareLink(defaultModalShareStudyQRcode);
					}}
					shouldCloseOnOverlayClick={true}
					height={null}
					width={null}
					customClassNameContent={'bg-white w-56 break-words custom-scrollbar overflow-x-hidden'}
					paddingContent={'p-2.5'}
				>
					{stateShareLink.children}
				</Modal>
			)}
		</>
	);
};

/**
 * Content của popup 'Share study QR code'
 */
const QRShareLink: FC<GetShareLinkDTO> = ({ link, shareKey }) => {
	return (
		<>
			<QRCode
				size={200}
				value={link}
			/>
			<a
				href={link}
				className="text-sm text-primary-active"
			>
				{link}
			</a>
			{shareKey && <p className="text-sm text-black">Password: {shareKey}</p>}
		</>
	);
};
export default ShareLinkButton;
