import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

function NoSegmentationRow({ onSegmentationAdd, addSegmentationClassName = '' }) {
	const { t } = useTranslation('SegmentationTable');
	return (
		<div
			className={`group ${addSegmentationClassName}`}
			onClick={onSegmentationAdd}
		>
			<div className="flex items-center rounded-[4px] text-primary-active group-hover:cursor-pointer group-hover:bg-secondary-dark">
				<div className="grid h-[28px] w-[28px] place-items-center">
					<Icon name="icon-add" />
				</div>
				<span className="text-[13px]">{t('Add segmentation')}</span>
			</div>
		</div>
	);
}

export default NoSegmentationRow;
