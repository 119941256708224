import { useCallback, useEffect, useMemo, useState } from 'react';

import { DicomMetadataStore, ServicesManager, Enums as CoreEnums } from '@core';
import { PatientHistoryDTO, PatientHistoryFilter, RESOURCES } from 'types';
import { useAppConfig } from './../state/appConfig';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '@utils';
export const usePatientHistory = (servicesManager: ServicesManager) => {
	const appConfig = useAppConfig();
	const baseURL = appConfig.dataSources[0].configuration.apiRoot;
	const { viewportGridService, cornerstoneViewportService } = servicesManager.services;

	const activeViewportID = viewportGridService.getActiveViewportId();

	const study = useMemo(() => {
		const viewportInfo = cornerstoneViewportService.getViewportInfo(activeViewportID);
		const viewportData = viewportInfo.getViewportData();
		if (
			viewportData.viewportType === CoreEnums.ViewportTypes.ORTHOGRAPHIC ||
			viewportData.viewportType === CoreEnums.ViewportTypes.VOLUME_3D
		) {
			return DicomMetadataStore.getStudy(viewportData.data[0].StudyInstanceUID);
		} else {
			//@ts-ignore
			return DicomMetadataStore.getStudy(viewportData.data.StudyInstanceUID);
		}
	}, [activeViewportID, cornerstoneViewportService]);

	const params = new URLSearchParams(new URL(window.location.href).search);
	const sessionID = params.get('session');
	const [filter, setFilter] = useState<PatientHistoryFilter>({
		patientID: study.PatientID,
		startDate: format(new Date(), DEFAULT_DATE_FORMAT),
		endDate: format(new Date(), DEFAULT_DATE_FORMAT)
	});

	const [patientHistoryList, setPatientHistoryList] = useState<PatientHistoryDTO[]>([]);

	const updateSession = useCallback(
		async (studyInstanceUIDs: string[]) => {
			if (sessionID) {
				try {
					const response = await fetch(`${baseURL}/${RESOURCES.SESSION}`, {
						method: 'PUT',
						headers: {
							'content-type': 'application/json'
						},
						body: JSON.stringify({
							id: sessionID,
							studyInstanceUIDs
						})
					});
					return response;
				} catch (error) {
					console.log(error);
					// Handle any error happened.
				}
			}
		},
		[baseURL, sessionID]
	);
	/**
	 * CALL API TO GET PATIENT HISTORY
	 */
	const getPatientHistory = useCallback(
		async (payload: PatientHistoryFilter): Promise<PatientHistoryDTO[]> => {
			let patientHistories: PatientHistoryDTO[] = [];
			if (sessionID) {
				try {
					await fetch(`${baseURL}/${RESOURCES.STUDY}/search`, {
						method: 'POST',
						headers: {
							'content-type': 'application/json'
						},
						body: JSON.stringify({
							...payload,
							sessionID
						})
					})
						.then((response) => response.json())
						.then((data) => {
							patientHistories = data.body;
						});
				} catch (error) {
					console.log(error);
				}
			}
			return patientHistories.map((item) => {
				return { ...item, id: item.studyInstanceUID };
			});
		},
		[baseURL, sessionID]
	);

	useEffect(() => {
		const setPatientHistories = async () => {
			const data = await getPatientHistory({ ...filter });
			setPatientHistoryList(data);
		};
		setPatientHistories();
	}, [filter, getPatientHistory]);

	return { updateSession, patientHistoryList, filter, setFilter };
};
