// @ts-nocheck

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import IconButton from '../IconButton';

import './ExpandableToolbarButton.css';

const ExpandableToolbarButton = ({ type, id, isActive, onClick, icon, className, content: Content, contentProps }) => {
	const classes = {
		type: {
			primary: isActive ? 'text-black' : 'text-common-bright hover:bg-primary-dark hover:text-primary-light',
			secondary: isActive ? 'text-black' : 'text-white hover:bg-secondary-dark focus:bg-secondary-dark'
		}
	};

	const onChildClickHandler = (...args) => {
		onClick(...args);

		if (contentProps.onClick) {
			contentProps.onClick(...args);
		}
	};

	const onClickHandler = (...args) => {
		onClick(...args);
	};

	return (
		<div
			key={id}
			className="ExpandableToolbarButton"
		>
			<IconButton
				variant={isActive ? 'contained' : 'text'}
				className={classnames('mx-1', classes.type[type], isActive && 'ExpandableToolbarButton__arrow')}
				onClick={onClickHandler}
				key={id}
			>
				<Icon name={icon} />
			</IconButton>
			<div className="absolute z-10 pt-4">
				<div className={classnames('ExpandableToolbarButton__content w-48', className)}>
					<Content
						{...contentProps}
						onClick={onChildClickHandler}
					/>
				</div>
			</div>
		</div>
	);
};

const noop = () => {};

ExpandableToolbarButton.defaultProps = {
	id: '',
	icon: 'clipboard',
	isActive: false,
	type: 'primary',
	content: null,
	onClick: noop
};

ExpandableToolbarButton.propTypes = {
	/* Influences background/hover styling */
	type: PropTypes.oneOf(['primary', 'secondary']),
	id: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.string.isRequired,
	/** Expandable toolbar button content can be replaced for a customized content by passing a node to this value. */
	content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	contentProps: PropTypes.object
};

export default ExpandableToolbarButton;
