import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Icon, Select, Tooltip } from '../../components';

function SegmentationDropDownRow({
	segmentations = [],
	activeSegmentation,
	onActiveSegmentationChange,
	disableEditing = false,
	onToggleSegmentationVisibility,
	onSegmentationEdit,
	onSegmentationDownload,
	onSegmentationDownloadRTSS,
	storeSegmentation,
	onSegmentationDelete,
	onSegmentationAdd,
	addSegmentationClassName
}) {
	const handleChange = (option) => {
		onActiveSegmentationChange(option.value); // Notify the parent
	};

	const selectOptions = segmentations.map((s) => ({
		value: s.id,
		label: s.label
	}));
	const { t } = useTranslation('SegmentationTable');

	if (!activeSegmentation) {
		return null;
	}

	return (
		<div className="group mx-0.5 mt-[8px] flex items-center pb-[10px]">
			<div
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<Dropdown
					id="segmentation-dropdown"
					showDropdownIcon={false}
					alignment="left"
					itemsClassName={`text-primary-active ${addSegmentationClassName}`}
					showBorders={false}
					maxCharactersPerLine={30}
					list={[
						...(!disableEditing
							? [
									{
										title: t('Add new segmentation'),
										onClick: () => {
											onSegmentationAdd();
										}
									}
								]
							: []),
						...(!disableEditing
							? [
									{
										title: t('Rename'),
										onClick: () => {
											onSegmentationEdit(activeSegmentation.id);
										}
									}
								]
							: []),
						{
							title: t('Delete'),
							onClick: () => {
								onSegmentationDelete(activeSegmentation.id);
							}
						},
						...(!disableEditing
							? [
									{
										title: t('Export DICOM SEG'),
										onClick: () => {
											storeSegmentation(activeSegmentation.id);
										}
									}
								]
							: []),
						...[
							{
								title: t('Download DICOM SEG'),
								onClick: () => {
									onSegmentationDownload(activeSegmentation.id);
								}
							},
							{
								title: t('Download DICOM RTSTRUCT'),
								onClick: () => {
									onSegmentationDownloadRTSS(activeSegmentation.id);
								}
							}
						]
					]}
				>
					<div className="grid  h-[28px] w-[28px] cursor-pointer  place-items-center rounded-[4px] hover:bg-secondary-dark">
						<Icon name="icon-more-menu"></Icon>
					</div>
				</Dropdown>
			</div>
			{selectOptions?.length && (
				<Select
					id="segmentation-select"
					isClearable={false}
					onChange={handleChange}
					components={{
						DropdownIndicator: () => (
							<Icon
								name={'chevron-down-new'}
								className="mr-2"
							/>
						)
					}}
					isSearchable={false}
					options={selectOptions}
					value={selectOptions?.find((o) => o.value === activeSegmentation.id)}
					className="h-[26px] w-1/2 text-[13px] text-aqua-pale"
				/>
			)}
			<div className="flex items-center">
				<Tooltip
					position="bottom-right"
					content={
						<div className="flex flex-col">
							<div className="text-[13px] text-white">Series:</div>
							<div className="text-[13px] text-aqua-pale">{activeSegmentation.description}</div>
						</div>
					}
				>
					<Icon
						name="info-action"
						className="text-primary-active"
					/>
				</Tooltip>
				<div
					className="mr-1  grid h-[28px] w-[28px]  cursor-pointer place-items-center rounded-[4px] hover:bg-secondary-dark"
					onClick={() => onToggleSegmentationVisibility(activeSegmentation.id)}
				>
					{activeSegmentation.isVisible ? (
						<Icon
							name="row-shown"
							className="text-primary-active"
						/>
					) : (
						<Icon
							name="row-hidden"
							className="text-primary-active"
						/>
					)}
				</div>
			</div>
		</div>
	);
}

SegmentationDropDownRow.propTypes = {
	segmentations: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired,
	activeSegmentation: PropTypes.shape({
		id: PropTypes.string.isRequired,
		isVisible: PropTypes.bool.isRequired
	}),
	onActiveSegmentationChange: PropTypes.func.isRequired,
	disableEditing: PropTypes.bool,
	onToggleSegmentationVisibility: PropTypes.func,
	onSegmentationEdit: PropTypes.func,
	onSegmentationDownload: PropTypes.func,
	onSegmentationDownloadRTSS: PropTypes.func,
	storeSegmentation: PropTypes.func,
	onSegmentationDelete: PropTypes.func,
	onSegmentationAdd: PropTypes.func
};

export default SegmentationDropDownRow;
