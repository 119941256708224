import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Icon from '../Icon';
import InputRange from '../InputRange';

import ToolbarButton from '../ToolbarButton';
import './CinePlayer.css';

export type CinePlayerProps = {
	className: string;
	isPlaying: boolean;
	minCineRate?: number;
	maxCineRate?: number;
	stepCineRate?: number;
	cineRate?: number;
	onCineRateChange: (isPlaying: boolean, value: number) => void;
	onSkipback: () => void;
	onClose: () => void;
	disabled?: boolean;
};

const CinePlayer: React.FC<CinePlayerProps> = ({
	className,
	isPlaying,
	minCineRate,
	maxCineRate,
	stepCineRate,
	cineRate,
	onCineRateChange,
	onSkipback,
	disabled = false
}) => {
	const [cineRateState, setCineRateState] = useState(cineRate);
	const debouncedSetCineRate = debounce(onCineRateChange, 300);

	const getPlayPauseIconName = () => {
		if (disabled) {
			return 'icon-play';
		}
		return isPlaying ? 'icon-pause' : 'icon-play';
	};

	const handleSetCineRate = (cineRate: number) => {
		if (cineRate < minCineRate || cineRate > maxCineRate) {
			return;
		}
		setCineRateState(cineRate);
		debouncedSetCineRate(isPlaying, cineRate);
	};
	const fpsButtonClassNames = useMemo(
		() => `${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}  text-white w-4 flex items-center justify-center`,
		[disabled]
	);

	return (
		<div className={className}>
			<div className="flex flex-col items-center gap-2">
				<div className={'flex items-center gap-2'}>
					<ToolbarButton
						icon={'skipback'}
						onInteraction={() => {
							!disabled && onSkipback();
						}}
						dropdownContent="null"
						id="skipback"
						type="action"
						disabled={disabled && true}
						size="quickToolbar"
					/>
					<ToolbarButton
						icon={getPlayPauseIconName()}
						onInteraction={() => {
							!disabled && onCineRateChange(!isPlaying, cineRateState);
						}}
						dropdownContent="null"
						id="play"
						type="action"
						disabled={disabled && true}
						size="quickToolbar"
					/>

					<div className="flex h-6 items-stretch gap-1 rounded border border-common-light">
						<div
							className={`${fpsButtonClassNames} rounded-l`}
							onClick={() => {
								!disabled && handleSetCineRate(cineRateState - 1);
							}}
						>
							<Icon name="arrow-left-small" />
						</div>
						<div className="w-11 text-center text-sm leading-[22px] text-white group-hover/fps:text-primary-light">
							{`${cineRateState} FPS`}
						</div>
						<div
							className={`${fpsButtonClassNames} rounded-r`}
							onClick={() => {
								!disabled && handleSetCineRate(cineRateState + 1);
							}}
						>
							<Icon name="arrow-right-small" />
						</div>
					</div>
				</div>
				<div className="w-full pl-2">
					<InputRange
						inputClassName="w-full"
						value={cineRateState}
						minValue={minCineRate}
						maxValue={maxCineRate}
						step={stepCineRate}
						onChange={handleSetCineRate}
						showLabel={false}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
};

const noop = () => undefined;

CinePlayer.defaultProps = {
	isPlaying: false,
	minCineRate: 1,
	maxCineRate: 90,
	stepCineRate: 1,
	cineRate: 24,
	onCineRateChange: noop,
	onSkipback: noop
};

CinePlayer.propTypes = {
	/** Minimum value for range slider */
	minCineRate: PropTypes.number,
	/** Maximum value for range slider */
	maxCineRate: PropTypes.number,
	/** Increment range slider can "step" in either direction */
	stepCineRate: PropTypes.number,
	cineRate: PropTypes.number,
	/** 'true' if playing, 'false' if paused */
	isPlaying: PropTypes.bool.isRequired,
	onCineRateChange: PropTypes.func,
	onSkipback: PropTypes.func
};

export default CinePlayer;
