import absoluteUrl from './absoluteUrl';
import addAccessors from './addAccessors';
import b64toBlob from './b64toBlob';
import guid from './guid';
import ObjectPath from './objectPath';
import sortBy from './sortBy';
import uuidv4 from './uuidv4';
import writeScript from './writeScript';
//import loadAndCacheDerivedDisplaySets from './loadAndCacheDerivedDisplaySets';
import Queue from './Queue';
import debounce from './debounce';
import downloadCSVReport from './downloadCSVReport';
import { formatDate, formatDateTime } from './formatDate';
import formatPN from './formatPN';
import generateAcceptHeader from './generateAcceptHeader';
import hierarchicalListUtils from './hierarchicalListUtils';
import hotkeys from './hotkeys';
import imageIdToURI from './imageIdToURI';
import isDicomUid from './isDicomUid';
import isDisplaySetReconstructable from './isDisplaySetReconstructable';
import isEqualWithin from './isEqualWithin';
import { isImage } from './isImage';
import isLowPriorityModality from './isLowPriorityModality';
import makeCancelable from './makeCancelable';
import makeDeferred from './makeDeferred';
import progressTrackingUtils from './progressTrackingUtils';
import resolveObjectPath from './resolveObjectPath';
import roundNumber from './roundNumber';
import sopClassDictionary from './sopClassDictionary';
import sortInstancesByPosition from './sortInstancesByPosition';
import { seriesSortCriteria, sortStudy, sortStudyInstances, sortStudySeries, sortingCriteria } from './sortStudy';
import { getSplitParam, splitComma } from './splitComma';
import { subscribeToNextViewportGridChange } from './subscribeToNextViewportGridChange';
import urlUtil from './urlUtil';
import { getSelectedButtonSection } from './getSelectedButtonSection';

// Commented out unused functionality.
// Need to implement new mechanism for derived displaySets using the displaySetManager.

const utils = {
	addAccessors,
	guid,
	uuidv4,
	ObjectPath,
	absoluteUrl,
	sortBy,
	sortBySeriesDate: sortStudySeries,
	sortStudy,
	sortStudySeries,
	sortStudyInstances,
	sortingCriteria,
	seriesSortCriteria,
	writeScript,
	formatDate,
	formatDateTime,
	formatPN,
	b64toBlob,
	urlUtil,
	imageIdToURI,
	//loadAndCacheDerivedDisplaySets,
	makeDeferred,
	makeCancelable,
	hotkeys,
	Queue,
	isDicomUid,
	isEqualWithin,
	resolveObjectPath,
	hierarchicalListUtils,
	progressTrackingUtils,
	isLowPriorityModality,
	isImage,
	isDisplaySetReconstructable,
	debounce,
	roundNumber,
	downloadCSVReport,
	subscribeToNextViewportGridChange,
	splitComma,
	getSplitParam,
	generateAcceptHeader,
	sopClassDictionary,
	getSelectedButtonSection
};

export {
	ObjectPath,
	Queue,
	absoluteUrl,
	b64toBlob,
	debounce,
	downloadCSVReport,
	formatDate,
	formatDateTime,
	generateAcceptHeader,
	getSplitParam,
	guid,
	hierarchicalListUtils,
	hotkeys,
	imageIdToURI,
	isDicomUid,
	isDisplaySetReconstructable,
	isEqualWithin,
	isImage,
	isLowPriorityModality,
	makeCancelable,
	//loadAndCacheDerivedDisplaySets,
	makeDeferred,
	progressTrackingUtils,
	resolveObjectPath,
	roundNumber,
	sopClassDictionary,
	sortBy,
	sortInstancesByPosition,
	splitComma,
	urlUtil,
	writeScript
};

export default utils;
